import { useState } from 'react';
import { useHistory } from 'react-router';
import { Toolbar, Button, SaveButton } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DialogContextText from '@material-ui/core/DialogContentText';
import { makeStyles  } from '@material-ui/core/styles';

const useToolbarStyles = makeStyles(theme => ({
  bar: {
    display: 'flex',
    justifyContent: 'center',
  },
  cancel: {
    marginRight: '20px',
  }
}));

const CancelToolbar = ({ touched, goto, question, confirm, cancel, ...props}) => {
  const [open, setOpen] = useState(false);
  const classes = useToolbarStyles();
  const history = useHistory();

  const checkTouched = () => {
    if (touched) return false;
    return props.pristine;
  }

  const handleRedirect = () => {
    history.push(goto ?? '/dashboard');
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleModal = () => {
    if (checkTouched()) {
      handleRedirect();
    } else {
      setOpen(true);
    }
  }

  return (
    <Toolbar {...props} className={classes.bar}>
      <Button 
        label={cancel || 'Cancel'} 
        variant="contained" 
        size="medium"
        className={classes.cancel}
        onClick={handleModal} />
      <SaveButton disabled={checkTouched()} label={confirm}/>
      <Dialog
        open={open}
        onClose={handleClose}>
          <DialogTitle>
            Are you sure?
          </DialogTitle>
          <DialogContent>
            <DialogContextText>
              {question}
            </DialogContextText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" label="Stay" />
            <Button onClick={handleRedirect} color="primary" autoFocus label="Leave" />
          </DialogActions>
      </Dialog>
    </Toolbar>
  );
}

export default CancelToolbar;
