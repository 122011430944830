import * as React from 'react';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import {
    Button,
    Card,
    CardActions,
    CircularProgress,
    TextField,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Notification, useNotify } from 'react-admin';

import { API_URL } from '../../../constants/env';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#9e9e9e',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 550,
        marginTop: '12em',
        border: '2px solid',
        borderColor: theme.palette.primary.main,
    },
    form: {
        padding: '0 3em 1em 3em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '1em 3em 1em 3em',
    },
    link: {
        textDecoration: 'none',
        fontSize: '.9rem',
        color: theme.palette.primary.contrastText,
    },
    button: {
        width: '150px',
    },
    headings: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '1em 3em 1em 3em',
    },
    middleTitle: {
        margin: '1em 0 1em 0',
    },
    reset: {
        borderRadius: '0px',
        marginTop: '20em',
        padding: '2em'
    }
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => {
    return <TextField
                error={!!(touched && error)}
                helperText={touched && error}
                {...inputProps}
                {...props}
                fullWidth
            />
};

const ForgotPassword = ({ history }) => {
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful ] = useState(false);
    const classes = useStyles();
    const notify = useNotify();

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/password_reset/`, {
                method: 'POST',
                body: `email=${values.email}`,
                headers: new Headers({ 
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
            });
            const json = await response.json();
            if (response.status === 400) {
                throw new Error(json.email[0]);
            } else if (response.status === 200){
                setSuccessful(true);
                setTimeout(() => history.push('/login'), 5000);
            }
        } catch (e) {
            notify(e.message, 'warning');
        } finally {
            setLoading(false);
        }


    };

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Please provide an email';
        } else {
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!re.test(String(values.email.toLowerCase()))) {
                errors.email = 'Invalid email address: Please make sure it looks like this: email@website.com.'
            }
        }
        return errors;
    };


    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className={classes.main}>
                        {!successful ? (
                            <Card className={classes.card} rounded={'false'}>
                                <div className={classes.headings}>
                                    <Typography variant="h4" component="div">
                                        uLearn
                                    </Typography>
                                    <Typography variant="h6" component="div" className={classes.middleTitle}>
                                        Forgot your password?
                                    </Typography>
                                    <Typography component="p">
                                        To recover your password please enter your email below
                                    </Typography>
                                </div>
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <Field
                                            name="email"
                                            component={renderInput}
                                            label={'E-mail'}
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                                <CardActions className={classes.actions}>
                                    <Button
                                    variant="contained"
                                    type="button"
                                    component={Link}
                                    to='/login'
                                    color="primary"
                                    className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        className={classes.button}
                                    >
                                        {
                                            loading ? (
                                                <CircularProgress
                                                    size={25}
                                                    thickness={2}
                                                />
                                            ) : 'Submit'
                                        }
                                    </Button>
                                </CardActions>
                            </Card>
                        ) : <Card className={classes.reset} rounded={'false'}>
                                <Typography variant="h6" component="div">
                                    Reset password link was sent to your email address
                                </Typography>
                            </Card>}

                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

export default ForgotPassword;