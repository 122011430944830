import { Create, SimpleForm, TextInput,
         ImageInput, ImageField, NumberInput,
         required, maxLength, minValue, maxValue,
         Toolbar, Button, SaveButton, useCreate,
         useGetList, useNotify, FileInput, FileField } from 'react-admin';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DialogContextText from '@material-ui/core/DialogContentText';
import { makeStyles  } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { useState } from 'react';
import { useHistory } from 'react-router';

import { getStorage, NonInput } from '../../../utils';
import { API_URL } from '../../../constants/env';
import { types } from '../../../constants';

import { CodeInput } from './CodeInput';

const useStyles = makeStyles(theme => ({
  description: {
    width: '75%',
  },
  cover: {
    width: '30%',
  },
  qr: {
    width: '180px',
  },
  name: {
    width: '40%',
  },
  title: {
    fontSize: '1.4em',
    fontWeight: 'bold',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  load: {
    width: '180px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  blue: {
    color: '#3f51b5',
  },
  ref: {
    marginBottom: '15px'
  },
  first_chapter_html: {
    maxWidth: '300px'
  }
}));

const validateName = required('Please, enter the Name of the book');
const validateQR = [required('Please, enter the Number of QR codes'),
                    minValue(1, 'Number of QR codes should be greater than 0'),
                    maxValue(100, 'Number of QR codes should be less than 101')];
const validateDescription = maxLength(500, 'Description should be less than 500 symbols');

const useToolbarStyles = makeStyles(theme => ({
  bar: {
    display: 'flex',
    justifyContent: 'center',
  },
  cancel: {
    marginRight: '20px',
  }
}));

const BookToolbar = ({ goto, question, confirm, 
                       nextStep, onSave, reqLoading, 
                       ...props}) => {
  const [open, setOpen] = useState(false);
  const classes = useToolbarStyles();
  const history = useHistory();

  const handleRedirect = () => {
    history.push(goto ?? '/dashboard');
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleModal = () => {
    if (props.pristine) {
      handleRedirect();
    } else {
      setOpen(true);
    }
  }

  return (
    <Toolbar {...props} className={classes.bar}>
      <Button 
        label="Cancel" 
        variant="contained" 
        size="medium"
        className={classes.cancel}
        onClick={handleModal}
        disabled={reqLoading} />
      <SaveButton
        disabled={nextStep ? false : props.pristine} 
        label={confirm}
        onSave={onSave} />
      <Dialog
        open={open}
        onClose={handleClose}>
          <DialogTitle>
            Are you sure?
          </DialogTitle>
          <DialogContent>
            <DialogContextText>
              {question}
            </DialogContextText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" label="Stay" />
            <Button onClick={handleRedirect} color="primary" autoFocus label="Leave" />
          </DialogActions>
      </Dialog>
    </Toolbar> 
  );
}

const BookCreate = props => {
  const [initialStep, setInitalStep] = useState(true);
  const [codes, setCodes] = useState([]);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [create] = useCreate();
  const history = useHistory();
  const notify = useNotify();
  const classes = useStyles();

  const { data: categoriesObj } = useGetList('content_categories', {}, {}, { nopager: '1' });
  const { data: subscriptionsObj } = useGetList('subscription', {}, {}, { nopager: 1 });
  const categories = Object.values(categoriesObj);
  const subscriptions = Object.values(subscriptionsObj);

  const formatData = payload => {
    const data = {};
    const checked = {};
    const form = {};
    data['content_number'] = payload.content_number;
    data['id'] = id;
    const bookContent = [];
    for (const key in payload) {
      const index = key.indexOf('-id');
      const id = key.slice(0, index);
      if (index > 0 && !(id in checked)) {
        checked[id] = [];
        const qrObject = {
          id: +id,
        }
        for (const key in payload) {
          if (key.startsWith(id)) {

            if (key.includes('chapter')) {
              qrObject.chapter = +payload[key];
              continue;
            }
            
            if (key.includes('page')) {
              qrObject.page = +payload[key];
              continue;
            }
            if (key.includes('category')) {
              qrObject['content_category'] = payload[key] ? +payload[key] : null;
              continue;
            }
            if (key.includes('qrtitle')) {
              qrObject.description = payload[key];
              continue;
            }

            if (key.includes('file')) {
              if (!qrObject['bookscontent_file']) {
                qrObject['bookscontent_file'] = [];
              }
              const filePos = key.indexOf('-file-') + 6;
              const fileIndex = key.charAt(filePos);
              if (!checked[id].includes(fileIndex)) {
                checked[id].push(fileIndex);
                const fileObj = {};
                let fileFlag = true;
                for (const key in payload) {
                  if (key.startsWith(`${id}-id-file-${fileIndex}`)) {
                    if (key.includes('title')) {
                      fileObj.label = payload[key];
                      continue;
                    }

                    
                    if (key.includes('type') && !key.includes('subscription')) {
                      fileObj.type = payload[key];
                      continue;
                    }
                    if (key.includes('subscription_type')) {
                      fileObj.subscription_type = payload[key];
                      continue;
                    }
                    if (key.includes('link')) {
                      fileObj.link = payload[key];
                      continue;
                    }
                    if (key.includes('form')) {
                      form[key] = payload[key];
                      continue;
                    }
                    if (key.includes('status')) {
                      fileFlag = payload[key];
                      continue;
                    }
                  }
                }
                if (fileFlag) {
                  qrObject['bookscontent_file'].push(fileObj);
                }
                
              }
            }
          }
        }
        if (codes.find(e => +e.id === +qrObject.id)) {
          bookContent.push(qrObject);
        }
        
      }      
    }


    if (data['first_chapter_html'] && data['first_chapter_html']['rawFile']) {
      data['first_chapter_html'] = data['first_chapter_html']['rawFile'];
    }

    data['book_bookcontent'] = bookContent;
    return [data, form]

  }

  const updateBook = async data => {
    setLoading(true);
    try {
      const storage = getStorage();
      const token = storage.getItem('token');
      const response = await fetch(`${API_URL}/admin/books/${id}/`, {
        method: 'PATCH',
        body: data,
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      const json = await response.json();
      if (response.status === 200) {
        setLoading(false)
        history.push('/books');
        return true;
      } else {
        throw new Error(json.error);
      }
    } catch (e) {
      notify(e.message, 'warning');
      setLoading(false);
      return false;
    }
  }

  const saveHandler = async (payload, redirect) => {
    if (initialStep) {
      setLoading(true);

      if (payload['first_chapter_html'] && payload['first_chapter_html']['rawFile']) {
        payload['first_chapter_html'] = payload['first_chapter_html']['rawFile'];
      }

      try {
        const book = await create('books', payload, { returnPromise: true });
        setCodes(book.data.book_bookcontent);
        setId(book.data.id);
        setLoading(false);
        setInitalStep(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      const [data, form] = formatData(payload);
      const formData = new FormData();
      for (const key in form) {
        formData.append(key, form[key].rawFile);
      }

      const bookContents = data?.book_bookcontent?.length ? data.book_bookcontent : codes.map(code => ({id: code.id}));

      formData.append('json', JSON.stringify({
        ...data,
        book_bookcontent: bookContents
      }));
      
      await updateBook(formData);

    }
  }

  const deleteCode = id => {
    const newCodes = codes.filter(e => e.id !== id);
    setCodes(newCodes);
  }

  const downloadZip = async () => {
    try {
      const storage = getStorage();
      const token = storage.getItem('token');
      const response = await fetch(`${API_URL}/admin/books/${id}/download_zip/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `qr_codes_for_bookid_${id}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.log(e);
    } 
  }

  const loadMore = async () => {
    try {
      const storage = getStorage();
      const token = storage.getItem('token');
      const response = await fetch(`${API_URL}/admin/books/${id}/add_one_more_content/`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      const json = await response.json();
      setCodes([...codes, json]);
    } catch (e) {
      console.log(e);
    }
  }

  const toolbar = <BookToolbar 
    goto="/books"
    question="Are you sure, you want to cancel the creation?"
    confirm={initialStep ? "Next" : "Save"}
    nextStep={!initialStep}
    onSave={saveHandler}
    reqLoading={loading} />;

  const refDiv = <div className={classes.ref}>
    <span className={initialStep ? classes.blue : ''}>General Info</span>
    <span> &gt; </span>
    <span className={!initialStep ? classes.blue : ''}>Book Content</span>
  </div>

  const initialFields = <div className={classes.container}>
        <NonInput>
          <>
            <p className={classes.title}>Add new Book</p>
            {refDiv}
          </>
        </NonInput>        
        <ImageInput 
          source="cover" 
          label="Book cover" 
          accept="image/*"
          maxSize={5000000}
          className={classes.cover}>
          <ImageField source="url" />
        </ImageInput>
        <FileInput
          source='first_chapter_html'
          placeholder='Select a File'
          label="First chapter html"
          className={classes['first_chapter_html']}
          accept='text/html'
        >
          <FileField source='url' title='title'/>
        </FileInput>
        <TextInput 
          source="name" 
          disabled={loading}
          label="Name of the book" 
          className={classes.name} 
          validate={validateName} />
        <TextInput 
          source="description" 
          multiline
          disabled={loading}
          validate={validateDescription}
          className={classes.description}/>
        <NumberInput 
          source="content_number"
          disabled={loading}
          label="Number of QR codes"
          max={100}
          min={1} 
          className={classes.qr}
          validate={validateQR} />
  </div>;

  const contentFields = <div className={classes.container}>
    <NonInput>
      <>
        <div className={classes.header}>
          <p className={classes.title}>Add new Book</p>
          <Button
            label="Download the file with QR codes"
            onClick={() => downloadZip()}
          />
        </div>
        {refDiv}
      </>
    </NonInput> 
    {codes.map((e, i) => (
      <CodeInput 
        key={e.id}
        imgLink={e.qr_code_file}
        id={e.id}
        onDelete={deleteCode}
        categories={categories}
        types={types}
        subs={subscriptions}/>
    ))}
    <Button 
      label="Add more"
      className={classes.load}
      size="large"
      onClick={() => loadMore()}>
      <AddCircleOutlineIcon />
    </Button>
  </div>;

  return  (
    <Create {...props} title="Add new Book" >
      <SimpleForm toolbar={toolbar} redirect="list">
        {initialStep ? initialFields : contentFields}
      </SimpleForm>
    </Create>
  );
}

export default BookCreate;