import { useCallback } from 'react';
import {DatagridHeaderCell, useListContext,} from 'react-admin';
import { TableHead, TableRow } from '@material-ui/core';

const CustomHeader = (props) => {

  const {
    currentSort,
    setSort,
  } = useListContext(props);

  const updateSortCallback = useCallback(
    event => {
        event.stopPropagation();
        const newField = event.currentTarget.dataset.field;
        const newOrder =
            currentSort.field === newField
                ? currentSort.order === 'ASC'
                    ? 'DESC'
                    : 'ASC'
                : event.currentTarget.dataset.order;

        setSort(newField, newOrder);
    },
    [currentSort.field, currentSort.order, setSort]
  );

  const updateSort = setSort ? updateSortCallback : null;

  return (
    <TableHead>
      <TableRow>
          {props.children.map(child => (
            <DatagridHeaderCell
              key={child.props.source} 
              style={{
                width: props.columns[child.props.source]?.width,
                textAlign: props.columns[child.props.source]?.align ?? 'inherit', 
              }}
              currentSort={currentSort}
              field={child}
              isSorting={
                currentSort.field === (
                  child.props.sortBy || child.props.source
                )
              }
              updateSort={updateSort}
             />
          ))}
      </TableRow>
    </TableHead>
  );
}

export default CustomHeader;