import {
    List,
    Datagrid,
    Pagination,
    CreateButton,
    Create,
    Edit,
    TextField,
    TextInput,
    EditButton,
    useRecordContext,
    TopToolbar,
    useUpdate,
    useRefresh,
    Button,
    Link,
    // DeleteWithConfirmButton
} from 'react-admin';
import CustomHeader from '../../layout/CustomHeader';
import { makeStyles  } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { ClassForm } from './ClassForm';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FlagIcon from '@material-ui/icons/Flag';
import AddBoxIcon from '@material-ui/icons/AddBox';

const useStyles = makeStyles(theme => ({
    actions: {
      display: 'flex',
      justifyContent: 'center',
    }
}));

const ClassesPagination = props => <Pagination
    rowsPerPageOptions={[]}
    {...props}
/>;

const ActionField = (props) => {

    const classes = useStyles();

    const record = useRecordContext(props);

    const [update] = useUpdate();

    const refresh = useRefresh();

    const onChangeRecord = (data) => {

        update('testclasses', record.id, data, record);
        refresh();
    }
    
    return (
        <div className={classes.actions}>
            <Tooltip title='Subject'>
                <Button
                    component={Link}
                    to={{
                        pathname: '/testsubjects',
                        search: `filter=${JSON.stringify({'testclass': record.id})}`,
                    }}
                >
                    <AddBoxIcon/>
                </Button>
            </Tooltip>
            <Tooltip title='Set Active'>
                <Button
                    onClick={() => onChangeRecord({is_active: !record.is_active})}
                    color={record.is_active ? 'primary' : 'default'}
                >
                    <FlagIcon/>
                </Button>
            </Tooltip>
            <Tooltip title='Set default'>
                <Button
                    onClick={() => onChangeRecord({is_default: !record.is_default})}
                    color={record.is_default ? 'primary' : 'default'}
                >
                    <CheckCircleIcon/>
                </Button>
            </Tooltip>
            <Tooltip title='Edit'>
                <EditButton
                    basepath='testclasses'
                    record={record} 
                    label={null}
                />
            </Tooltip>
            {/* <Tooltip title='Delete'>
                <DeleteWithConfirmButton
                    record={record}
                    label=''
                    confirmTitle='Delete class'
                    undoable={false}
                />
            </Tooltip> */}
        </div>
    )
};

const ClassesListActions = () => {
    return (
        <TopToolbar>
            <CreateButton 
                label='Add new class'
            />
        </TopToolbar>
    )
}

const classFilter = [
    <TextInput
        source='name'
        label='Name'
        alwaysOn
    />
];

export const ClassesList = props => {

    const columns = {
        name: {
            width: '75%',
        },
        actions: {
            width: '25%',
            align: 'center'
        }
    };

    return (
        <>
            <List
                {...props}
                title='Manage classes'
                bulkActionButtons={false}
                sort={{
                    field: 'id',
                    order: 'desc'
                }}
                exporter={false}
                pagination={<ClassesPagination/>}
                filters={classFilter}
                actions={<ClassesListActions/>}
            >
                <Datagrid header={<CustomHeader columns={columns}/>}>
                    <TextField
                        source='name'
                        label='Name'
                    />
                    <ActionField
                        source='actions'
                        sortable={false}
                    />
                </Datagrid>
            </List>
        </>
    );
}

export const ClassCreate = (props) => {
    return (
        <Create {...props}>
            <ClassForm {...props}/>
        </Create>
    );
}

export const ClassEdit = (props) => {
    return (
        <Edit {...props}>
            <ClassForm {...props}/>
        </Edit>
    );
}