import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles  } from '@material-ui/core/styles';
import {
    useDataProvider,
    useNotify,
    Button,
    useGetList
} from 'react-admin';
import {
    Grid,
    TextField,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import * as yup from 'yup';
import { useFormik } from 'formik';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: '#f4f4f4',
        textAlign: 'center',
        padding: 16
    },
    headerContainer: {
        margin: '32px 16px 0'
    },
    cancel: {
        marginRight: 20
    },
    container: {
        padding: 16
    }
}));

export const SubjectForm = (props) => {

    const { record } = props;

    const classes = useStyles();

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const history = useHistory();

    const [initialValues, setInitialValues] = useState({
        name: '',
        testclass_id: '',
        is_active: false
    });

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required(),
        testclass_id: yup.string()
            .required()

    });

    useEffect(() => {

        if (record.id) {
            setInitialValues({
                name: record.name,
                is_active: record.is_active,
                testclass_id: record.testclass_id
            });
        }
    }, [record]);

    const { data: classesObj } = useGetList('testclasses', {}, {})

    const classesList = Object.values(classesObj);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (data) => {

            if (record?.id) {

                dataProvider.update('testsubjects', {
                    id: record.id,
                    data,
                    previousData: record,
                }, {
                    onSuccess: () => {
                        notify('Subject was updated successful', 'success');
                        history.push('/testsubjects');
                    },
                    onFailure: (error) => {
                        notify('Something went wrong', 'error');
                    }
                });
            } else {

                dataProvider.create('testsubjects', {
                    data
                }, {
                    onSuccess: () => {
                        notify('Subject was created successful', 'success');
                        history.push('/testsubjects');
                    },
                    onFailure: (error) => {
                        notify('Something went wrong', 'error');
                    }
                });
            }
        }
    });

    const onCancel = () => {
        history.push('/testsubjects');
    }


    return (
        <div>
            <h2 className={classes.headerContainer}>{record?.id ? 'Edit' : 'Add new'} subject</h2>
            <form
                onSubmit={formik.handleSubmit}
            >
                <div className={classes.container}>
                    <Grid container>
                        <Grid item sm={6}>
                            <FormGroup>
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    name='name'
                                    label='Name'
                                    variant='filled'
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormControl
                                    fullWidth
                                    error={formik.touched.testclass && formik.errors.testclass}
                                >
                                <InputLabel id='class-select-field'>Classes</InputLabel>
                                <Select
                                    labelId='class-select-field'
                                    id='class-select'
                                    value={formik.values.testclass_id || ''}
                                    label='Class'
                                    name='testclass_id'
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value=''>Select class</MenuItem>
                                    {classesList && classesList.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox/>}
                                    name='is_active'
                                    checked={formik.values.is_active}
                                    label='Active'
                                    onChange={formik.handleChange}
                                />
                            </FormGroup>

                        </Grid>
                    </Grid>
                </div>
                <div className={classes.toolbar}>
                    <Button
                        label='Cancel'
                        variant='contained'
                        size='medium'
                        onClick={onCancel}
                        className={classes.cancel}
                    />
                    <Button
                        label='Save'
                        type='submit'
                        variant='contained'
                        size='medium'
                    />
                    </div>
            </form>
        </div>
    );
}