import { useState } from 'react';
import { List, Datagrid, TextField, Pagination, useRecordContext, 
         Button, useUpdate, useRefresh, TextInput } from 'react-admin';
import { Tooltip, Dialog, DialogActions, DialogContent, 
         DialogTitle } from '@material-ui/core';
import DialogContextText from '@material-ui/core/DialogContentText';
import { makeStyles  } from '@material-ui/core/styles';
import CustomHeader from '../../layout/CustomHeader';



const ArchivePagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const useStyles = makeStyles(theme => ({
  main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#9e9e9e',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    fontSize: '14px',
    color: 'red',
  },
}));

const ActionsField = (props) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const record = useRecordContext(props);
  const [update, { loading, error }] = useUpdate();
  const refresh = useRefresh();
  const diff = { is_archived: false };

  const handleClose = () => {
    setOpen(false);
  }

  const handleArchive = () => {
    update('archive', record.id, diff, record);
    refresh();
  }

  if (error) {
    console.log(error);
  }
  
  return (
    <div className={classes.actions}>
      <Tooltip title="Unarchive">
        <div>
          <Button onClick={() => setOpen(true)} label="Unarchive">
          </Button>
        </div>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}>
          <DialogTitle>
            Confirm
          </DialogTitle>
          <DialogContent>
            <DialogContextText>
            Are you sure you want to unarchive this book?
            </DialogContextText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" label="Cancel" />
            <Button onClick={handleArchive} color="primary" autoFocus label="Unarchive" />
          </DialogActions>
      </Dialog>
    </div>
  );
}

const archiveFilter = [
  <TextInput source="name" label="Search" alwaysOn />
];

const ArchiveList = props => {
  const columns = {
    name: {
      width: '70%',
    },
    content_number: {
      width: '15%',
    },
    actions: {
      width: '15%',
      align: 'center',
    },
  };

  return (
    <List 
      pagination={<ArchivePagination />} 
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      filters={archiveFilter}
      actions={false}
      {...props} >
        <Datagrid header={<CustomHeader columns={columns} />} >
          <TextField source="name" label="Book" />
          <TextField source="content_number" label="QR" sortable={false} />
          <ActionsField source="actions" sortable={false}/>
        </Datagrid>
    </List>
  );
};

export default ArchiveList;