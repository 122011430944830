import { List, Datagrid, TextField, Pagination, useRecordContext, 
         EditButton, CreateButton, TextInput, TopToolbar,
         DeleteWithConfirmButton } from 'react-admin';
import { Tooltip } from '@material-ui/core';
import { makeStyles  } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CustomHeader from '../../layout/CustomHeader';


const CategoryPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const useStyles = makeStyles(theme => ({
  main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#9e9e9e',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const ActionsField = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);

  return (
    <div className={classes.actions}>
      <Tooltip title="Edit">
        <div>
        <EditButton basepath='/content_categories' record={record} label="">
          <CreateIcon />
        </EditButton>
        </div>
      </Tooltip>
      <Tooltip title="Delete">
        <div>
          <DeleteWithConfirmButton 
            record={record} 
            label=""
            confirmTitle="Delete content category"
            undoable={false}>
          </DeleteWithConfirmButton>
        </div>
      </Tooltip>
    </div>
  );
}

const categoryFilter = [
  <TextInput source="name" label="Search" alwaysOn />
];

const CategoryListActions = (props) => {
  return (
    <TopToolbar>
      <CreateButton label="Add new category" />
    </TopToolbar>
  );
};

const CategoryList = props => {
  const columns = {
    name: {
      width: '85%',
    },
    actions: {
      width: '15%',
      align: 'center'
    },
  };

  return (
    <List 
      pagination={<CategoryPagination />} 
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      filters={categoryFilter}
      actions={<CategoryListActions />}
      {...props} >
        <Datagrid header={<CustomHeader columns={columns}/>} >
          <TextField source="name" label="Category" />
          <ActionsField source="actions" sortable={false}/>
        </Datagrid>
    </List>
  );
};

export default CategoryList;