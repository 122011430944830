import { API_URL, CLIENT_ID, CLIENT_SECRET } from '../constants/env';

const getStorage = () => localStorage.getItem('storage') === 'local' ? localStorage : sessionStorage;

const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password, remember }) => {
    const request = new Request(API_URL + '/oauth2/token/', {
      method: 'POST',
      body: `grant_type=password&username=${username}&password=${password}`,
      headers: new Headers({ 
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${btoa(unescape(encodeURIComponent(`${CLIENT_ID}:${CLIENT_SECRET}`)))}`
      }),
    });
    
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(auth => {
        if (!auth.user.is_admin) {
          throw new Error('You have no permissions')
        }
        const storageType = remember ? 'local' : 'session';
        localStorage.setItem('storage', storageType);
        const storage = getStorage();
        storage.setItem('token', auth.access_token);
        const now = new Date().getTime();
        const expires = 36000 * 1000;
        const expirationDate = now + expires;
        storage.setItem('expires', expirationDate);
        const userObj = { id: auth.user.id, fullName: auth.user.username, email: auth.user.email, avatar: auth.user.profile.image };
        const user = JSON.stringify(userObj);
        storage.setItem('user', user);
      })
      .catch((e) => {
        console.dir(e);
        throw new Error(e.message.startsWith('Bad') ? 
          'Wrong username or password!' : e.message);
      })

  },
  // called when the user clicks on the logout button
  logout: () => {
    const storage = getStorage();
    const token = storage.getItem('token');
    storage.removeItem('token');
    storage.removeItem('expires');
    storage.removeItem('user');
    localStorage.removeItem('storage');
    const request = new Request(API_URL + '/oauth2/revoke_token/', {
      method: 'POST',
      body: `token=${token}`,
      headers: new Headers({ 
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${btoa(unescape(encodeURIComponent(`${CLIENT_ID}:${CLIENT_SECRET}`)))}`
      }),
    });
    fetch(request)
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: (error) => {
    const status = error.status;
    if (status === 400) {
        const storage = getStorage();
        storage.removeItem('token');
        storage.removeItem('expires');
        storage.removeItem('user');
        localStorage.removeItem('storage');
        return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const storage = getStorage();
    const token = storage.getItem('token');
    if (!token) return Promise.reject();
    return +storage.getItem('expires') > Date.now() 
      ? Promise.resolve()
      : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    try {
      const storage = getStorage();
      const data = JSON.parse(storage.getItem('user'));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
  }
  }
};

export default authProvider