import {
    List,
    Datagrid,
    Pagination,
    Create,
    Edit,
    TextField,
    EditButton,
    useRecordContext,
    TopToolbar,
    TextInput,
    Button,
    DeleteWithConfirmButton,
    CreateButton
} from 'react-admin';
import CustomHeader from './../../layout/CustomHeader';
import { makeStyles  } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { SubjectForm } from './SubjectForm';
import AddBoxIcon from '@material-ui/icons/AddBox';

const useStyles = makeStyles(() => ({
    actions: {
      display: 'flex',
      justifyContent: 'center',
    }
}));

const SubjectsPagination = props => <Pagination
    rowsPerPageOptions={[]}
    {...props}
/>;

const SubjectListActions = () => {
    
    return (
        <TopToolbar>
            <CreateButton
                label='Add new subject'
            />
        </TopToolbar>
    );
};

const subjectFilter = [
    <TextInput source='name' label='Search by name' alwaysOn/>,
    <TextInput source='testclass__name__icontains' label='Search by class' alwaysOn/>,
];

const ActionField = (props) => {

    const classes = useStyles();

    const record = useRecordContext(props);

    return (
        <div className={classes.actions}>
            <Tooltip title='Question'>
                <Button>
                    <AddBoxIcon/>
                </Button>
            </Tooltip>
            <Tooltip title='Edit'>
                <EditButton
                    basePath='testsubjects'
                    record={record}
                    label={null}
                />
            </Tooltip>
            
            <Tooltip title='Delete'>
                <DeleteWithConfirmButton
                    record={record}
                    label=''
                    confirmTitle='Delete subject'
                    undoable={false}
                />
            </Tooltip>
        </div>
    );
};

export const SubjectList = (props) => {

    const columns = {
        name: {
            width: '40%',
        },
        testclass_name: {
            width: '40%',
        },
        actions: {
            width: '20%',
            align: 'center'
        },
    };

    return (
        <>
            <List
                {...props}
                title='Manage subjects'
                bulkActionButtons={false}
                sort={{
                    field: 'id',
                    order: 'desc',
                }}
                exporter={false}
                pagination={<SubjectsPagination/>}
                actions={<SubjectListActions/>}
                filters={subjectFilter}
            >
                <Datagrid 
                    header={
                        <CustomHeader
                            columns={columns}   
                        />
                    }
                >
                    <TextField
                        source='name'
                        label='Name'
                    />
                    <TextField
                        source='testclass_name'
                        label='Class'
                    />
                    <ActionField
                        source='actions'
                        sortable={false}
                    />
                </Datagrid>
            </List>
        </>
    );
}

export const SubjectCreate = (props) => {
    return (
        <Create {...props}>
            <SubjectForm {...props}/>
        </Create>
    );
};

export const SubjectEdit = (props) => {
    return (
        <Edit {...props}>
            <SubjectForm {...props}/>
        </Edit>
    );
}