import { List, Datagrid, TextField, Pagination, useRecordContext, 
         EditButton, Button, useUpdate, useRefresh, CreateButton,
         TextInput, TopToolbar } from 'react-admin';
import { Tooltip } from '@material-ui/core';
import { makeStyles  } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import ArchiveIcon from '@material-ui/icons/Archive';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import CustomHeader from '../../layout/CustomHeader';


const BookPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const useStyles = makeStyles(theme => ({
  main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#9e9e9e',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const ActionsField = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const [update, { loading, error }] = useUpdate();
  const refresh = useRefresh();
  const diff = { is_archived: true, book_bookcontent: [] };

  const handleArchive = () => {
    update('books', record.id, diff, record);
    refresh();
  }

  const handleRecommend = () => {
    update('books', record.id, { is_recommended: !record.is_recommended }, record);
    refresh();
  }

  if (error) {
    console.log(error);
  }
  
  return (
    <div className={classes.actions}>
      <Tooltip title="Edit">
        <div>
        <EditButton basepath='/books' record={record} label="">
          <CreateIcon />
        </EditButton>
        </div>
      </Tooltip>
      <Tooltip title="Archive">
        <div>
          <Button onClick={handleArchive}>
            <ArchiveIcon />
          </Button>
        </div>
      </Tooltip>
      <Tooltip title="Recommend">
        <div>
          <Button 
            onClick={handleRecommend}
            color={record.is_recommended ? 'primary' : 'default'}
          >
            <AssistantPhotoIcon />
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}

const booksFilter = [
  <TextInput source="name" label="Search" alwaysOn />
];

const BooksListActions = (props) => {
  return (
    <TopToolbar>
      <CreateButton label="Add new book" />
    </TopToolbar>
  );
};

const BookList = props => {
  const columns = {
    name: {
      width: '70%',
    },
    content_number: {
      width: '15%',
    },
    actions: {
      width: '15%',
      align: 'center',
    },
  };
  
  return (
    <List 
      pagination={<BookPagination />} 
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      filters={booksFilter}
      actions={<BooksListActions />}
      {...props} >
        <Datagrid header={<CustomHeader columns={columns}/>} >
          <TextField source="name" label="Book" />
          <TextField source="content_number" label="QR" sortable={false} />
          <ActionsField source="actions" sortable={false}/>
        </Datagrid>
    </List>
  );
};

export default BookList;