import {
    List, 
    Datagrid,
    Pagination,
    CreateButton,
    EditButton,
    TextField,
    FunctionField,
    useRecordContext,
    TopToolbar,
    TextInput,
    Edit,
    Create,
    DeleteButton,
    ShowButton,
    Show,
} from 'react-admin';
import CustomHeader from '../../layout/CustomHeader';
import { makeStyles  } from '@material-ui/core/styles';
import { 
    Tooltip, 
} from '@material-ui/core';
import { PromoCodeForm } from './PromoCodeForm';
import { PromoCodeUsage } from './PromoCodeUsage';

const useStyles = makeStyles(theme => ({
    actions: {
      display: 'flex',
      justifyContent: 'center',
    }
}));

const PromoCodePagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const PromoCodeListActions = () => {
    return (
      <TopToolbar>
        <CreateButton label="Add new voucher" />
      </TopToolbar>
    );
};

const subscriptionFilter = [
    <TextInput source='name' label='Name' alwaysOn/>
];

const ActionField = (props) => {

    const classes = useStyles();
    
    const record = useRecordContext(props);

    return (
        <div className={classes.actions}>
          <Tooltip title="Edit">
            <div>
                <EditButton label="" basePath='/promocode' record={record}/>
            </div>
          </Tooltip>
          <Tooltip title="Delete">
            <div>
                <DeleteButton label="" basePath='/promocode' record={record}/>
            </div>
          </Tooltip>
          <Tooltip title="View">
            <div>
                <ShowButton label="" basePath='/promocode' record={record}/>
            </div>
          </Tooltip>
        </div>
      );
};

export const PromoCodeList = props => {

    const columns = {
        name: {
            width: '15%'
        },
        subscription: {
            width: '15%',
        },
        code: {
            width: '15%'
        },
        usage: {
            width: '5%',
        },
        platform: {
            width: '5%'
        },
        date_frame: {
            width: '20%',
        },
        status: {
            width: '5%',
        },
        discount_percentage: {
            width: '10%',
        },
        actions: {
            width: '10%',
            align: 'center'
        }
    }

    return (
        <>
            <List
                {...props}
                title='Promo Codes'
                bulkActionButtons={false}
                sort={{
                    field: 'id',
                    order: 'desc'
                }}
                exporter={false}
                pagination={<PromoCodePagination/>}
                actions={<PromoCodeListActions/>}
                filters={subscriptionFilter}
            >
                <Datagrid header={<CustomHeader columns={columns}/>}>
                    <TextField
                        source='name'
                        sortable
                    />
                    <TextField
                        source='subscription.name'
                        label='Subscription'
                        sortable
                    />
                    <TextField
                        source='code'
                        label='Code'
                        sortable
                    />
                    <TextField
                        label='Allowed usage'
                        source='allowed_usages'
                        sortable
                    />
                    <FunctionField 
                        source='date_frame'
                        label='Date frame'
                        render={(record) => `${record.date_start} - ${record.date_end}`}
                        sortable
                    />
                    <TextField
                        source='platform'
                        label='Platform'
                        sortable
                    />
                    <TextField
                        source='status'
                        label='Status'
                        sortable
                    />
                    <TextField
                        source='discount_percentage'
                        label='Discount'
                        sortable
                    />
                    <ActionField
                        source='actions'
                        sortable={false}
                    />
                    
                </Datagrid>
            </List>
        </>
    );
}

export const PromoCodeEdit = (props) => {
    return (
        <Edit {...props}>
            <PromoCodeForm {...props}/>
        </Edit>
    );
}

export const PromoCodeCreate = (props) => {
    return (
        <Create {...props}>
            <PromoCodeForm {...props}/>
        </Create>
    );
}

export const PromoCodeShow = (props) => {
    return (
        <Show {...props}>
            <PromoCodeUsage {...props}/>
        </Show>
    );
}