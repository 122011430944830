import { useState, useEffect } from 'react';
import { NumberInput, SelectInput, TextInput, 
        FileInput, FileField, Button, required } from 'react-admin';
import { makeStyles  } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Delete from '@material-ui/icons/Delete';
import { NonInput } from '../../../utils';
import { useForm } from 'react-final-form';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '80vw',
    "&:not(:last-child)": {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42);',
      marginBottom: '25px',
      paddingBottom: '0px',
    }
  },
  deleted: {
    opacity: .3,
    pointerEvents: 'none',
  },
  content: {
    marginLeft: '30px',
    width: '100%',
  },
  qr: {
    display: 'flex',
    alignItems: 'top',
    width: '100%',
    minWidth: '1104px',
    justifyContent: 'space-between',
  },
  img: {
    width: '200px',
    height: '200px',
  },
  page: {
    width: '130px'
  },
  chapter: {
    width: '130px'
  },
  category: {
    width: '250px'
  },
  name: {
    width: '350px',
    marginTop: '8px'
  },
  type: {
    width: '130px',
    marginRight: '20px'
  },
  file: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  controls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '65px'
  },
  control: {
    height: '48px'
  },
  'content-title': {
    width: '300px',
    marginRight: '30px',
  },
  'file-input': {
    width: '300px',
  },
  'file-link': {
    width: '300px',
  },
  subscription: {
    width: '200px'
  },
  'dltcontent': {
    marginTop: '10px'
  }
}));

export const CodeInput = ({
    imgLink,
    id,
    categories,
    types,
    // subs,
    onDelete,
    category,
    page,
    title,
    files,
    edit,
    chapter
}) => {

  const subs = [
    {
      id: 'free',
      name: 'Free'
    }, 
    {
      id: 'premium',
      name: 'Premium',
    }
  ];


  const classes = useStyles();
  const [contentNumber, setContentNumber] = useState( files ? files.length : 0);
  const [content, setContent] = useState([
    { index: 1, active: false },
    { index: 2, active: false },
    { index: 3, active: false },
    { index: 4, active: false },
    { index: 5, active: false },
  ]);

  const typeToAccept = {
    'audi': 'audio/*',
    'vide': 'video/*',
    'imag': 'image/*',
  }

  const hasActiveContent = () => content.some(e => e.active);

  const addContent = () => {
    if (contentNumber < 5) {
      const number = contentNumber + 1;
      setContentNumber(number);
      const newContent = [...content];
      newContent.splice(number - 1, 1, { index: number, active: true })
      changeActive(number, true);
    }
  }

  const changeType = (id, value) => {
    const newContent = [...content];
    newContent[id - 1].type = value;
    setContent(newContent);
  }

  const changeActive = (fileId, value) => {
    const newContent = [...content];
    newContent[fileId - 1].active = value;
    setContent(newContent);
    if (!value) {
      setContentNumber(prevContent => prevContent - 1);
    }
    form.change(`${id}-id-file-${fileId}-status`, value);
  }

  const form = useForm();
  useEffect(() => {
    if (edit) {
      
      form.change(`${id}-id-chapter`, chapter);
      form.change(`${id}-id-page`, page);
      form.change(`${id}-id-category`, category);
      form.change(`${id}-id-qrtitle`, title);
      const newContent = [...content];
      for (let i = 0; i < files.length; i++) {
        newContent.splice(i, 1,  { index: i + 1, active: true, type: files[i].type });
        form.change(`${id}-id-file-${i + 1}-title`, files[i].label);
        form.change(`${id}-id-file-${i + 1}-type`, files[i].type);
        form.change(`${id}-id-file-${i + 1}-subscription_type`, files[i].subscription_type);
        form.change(`${id}-id-file-${i + 1}-contentid`, files[i].id);
        if (files[i].type === 'link') {
          form.change(`${id}-id-file-${i + 1}-link`, files[i].link);
        } else {
          const fileValue = {
            url: files[i].file,
            title: files[i].file
          }
          form.change(`${id}-id-file-${i + 1}-form`, fileValue);
        }
  
      }
      setContent(newContent);
    }
  }, []);


  return <div className={classes.container}>
    <NonInput>
      <img src={imgLink} alt="qr code" className={classes.img}/>
    </NonInput>
    <div className={classes.input}>
      <div className={classes.qr}>
        <NumberInput
          source={`${id}-id-chapter`}
          label="Chapter" 
          value={chapter}
          className={classes.chapter}
          min={1} 
        />
        <NumberInput
          source={`${id}-id-page`}
          label="Page" 
          value={page}
          className={classes.page}
          min={1} />
        <SelectInput
          source={`${id}-id-category`}
          label='Choose the category'
          value={category}
          choices={categories}
          className={classes.category}/>
        <TextInput
          source={`${id}-id-qrtitle`}
          label={'QR code title'}
          className={classes.name} />
        <div className={classes.controls}>
          <Tooltip title="Add a File">
            <div>
            <Button 
              onClick={addContent} 
              className={classes.control}
              disabled={contentNumber >= 5}>
                <ControlPoint />
            </Button>
            </div>
          </Tooltip>
          <Tooltip title="Delete">
            <div>
              <Button 
                onClick={() => onDelete(id)}
                className={classes.control}
                disabled={hasActiveContent()}>
                  <Delete />
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={classes.content}>
        {content.map(file => {
          if (!file.active) {
            return '';
          } else return (
            <div className={classes.file} key={file.index}>
              <TextInput
                source={`${id}-id-file-${file.index}-title`}
                label="Content title"
                className={classes['content-title']} />
              <SelectInput
                source={`${id}-id-file-${file.index}-type`}
                label='Choose type'
                choices={types}
                className={classes.type}
                validate={required('Please select a file type')}
                onChange={(e) => changeType(file.index, e.target.value)}
              />
              {
                file.type === 'link' ? 
                  <TextInput
                    source={`${id}-id-file-${file.index}-link`}
                    label="Content link"
                    validate={required('Please specify a link')}
                    className={classes['file-link']}
                  /> :
                  <FileInput
                    source={`${id}-id-file-${file.index}-form`}
                    placeholder='Select a File*'
                    label=""
                    className={classes['file-input']}
                    validate={required('Please select a file')}
                    accept={file.type ? typeToAccept[file.type] : '*'}
                  >
                    <FileField source="url" title="title" />
                  </FileInput>
              }
              <SelectInput
                source={`${id}-id-file-${file.index}-subscription_type`}
                label='Choose subscription'
                choices={subs}
                className={classes.subscription_type}
              />
              <Tooltip title="Delete content">
                <div className={classes.dltcontent}>
                  <Button 
                    onClick={() => changeActive(file.index, false)}
                    className={classes.control}>
                      <Delete />
                  </Button>
                </div>
              </Tooltip>
              <div
                source={`${id}-id-file-${file.index}-contentid`} >
              </div>     
              <div
                source={`${id}-id-file-${file.index}-status`} >
              </div>       
            </div>
          )
        })}
      </div>
    </div>
  </div>
};


