import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Button, Notification, useNotify } from 'react-admin';
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import { getStorage } from '../../../utils';
import { API_URL } from '../../../constants/env';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
  },
  field: {
    width: '40%',
    marginBottom: '10px',
  },
  footer: {
    display: 'flex',
    width: '40%',
    justifyContent: 'center',
    marginTop: '50px'
  },
  title: {
    fontSize: '1.4em',
    fontWeight: 'bold',
  }
}));


const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => {
  return <TextField
              error={!!(touched && error)}
              helperText={touched && error}
              {...inputProps}
              {...props}
              fullWidth
          />
};


const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();

  const onSubmit = async values => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
    if (values.newPassOne !== values.newPassTwo) {
      notify('Please make sure your passwords match', { type: 'warning' });
      return false;
    } else if (!re.test(values.newPassOne) || !re.test(values.newPassTwo)) {
      notify('Password must contain at least 6 characters including numbers and special characters like @#$', { type: 'warning' });
      return false;
    } else {
      setLoading(true);
      try {
        const data = new FormData();
        data.set('old_password', values.password);
        data.set('new_password', values.newPassOne);
        const storage = getStorage();
        const token = storage.getItem('token');
        const response = await fetch(`${API_URL}/admin/profile/223434/update_password/`, {
          method: 'PATCH',
          body: data,
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        const json = await response.json();
        if (response.status === 200) {
          notify('Password changed', { type: 'success' });
          setLoading(false)
          return true;
        } else {
          throw new Error(json.error);
        }
      } catch (e) {
        notify(e.message, 'warning');
        setLoading(false);
        return false;
      }
    }
  }

  const validate = values => {
    const errors = {};
    const noPasswordMessage = 'Password field can\'t be empty';
    if (!values.password) {
      errors.password = noPasswordMessage;
    } else if (!values.newPassOne) {
      errors.newPassOne = noPasswordMessage;
    } else if (!values.newPassTwo) {
      errors.newPassTwo = noPasswordMessage;
    } 
    return errors;
  }

  return (
    <div className={classes.main}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={async event => {
            const result = await handleSubmit(event);
            if (result) {
              form.reset();
              form.resetFieldState('password');
              form.resetFieldState('newPassOne');
              form.resetFieldState('newPassTwo');
            }
          }}>
            <p className={classes.title}>Change Password</p>
            <div className={classes.field}>
              <Field
                name="password"
                component={renderInput}
                label="Current Password"
                disabled={loading}
                type="password"
              />
            </div>
            <div className={classes.field}>
              <Field
                name="newPassOne"
                component={renderInput}
                label="New Password"
                disabled={loading}
                type="password"
              />
            </div>
            <div className={classes.field}>
              <Field
                name="newPassTwo"
                component={renderInput}
                label="Confirm Password"
                disabled={loading}
                type="password"
              />
            </div>
            <div className={classes.footer}>
              <Button 
                type="submit"
                label="Submit"
                size="large"
                variant="contained" />
            </div>
          </form>
        )}
      />
    <Notification />
    </div>
  )
}

export default ChangePassword;