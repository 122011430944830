import { useCallback, useState, useEffect } from 'react';
import {
    SimpleForm,
    useDataProvider,
    useNotify,
} from 'react-admin';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
    TextField,
    Grid,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SubscriptionToolbar } from './Toolbar';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    checkboxLineHeight: {
        lineHeight: 5,
    },
    formWidth: {
        width: '100%'
    },
}));

export const SubscriptionForm = (props) => {

    const classes = useStyles();

    const [initialValues, setInitialValues] = useState({
        name: '',
        price: 0,
        audio_duration: 0,
        video_duration: 0,
        image_duration: 0,
        link_data: 0,
        math_ocr_number: 0,
        is_active: false,
        audio_duration_checkbox: 0,
        video_duration_checkbox: 0,
        image_duration_checkbox: 0,
        link_data_checkbox: 0,
        math_ocr_number_checkbox: 0,
        subscription_description: [],
        subscription_uuid: null,
    });

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required()
            .min(3),
        price: yup.number()
            .min(0)
            .required(),
    });

    const record = props.record;

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const history = useHistory();

    useEffect(() => {
        
        if (Object.keys(record).length) {

            const values = {
                name: record.name || initialValues.name,
                price: record.price || initialValues.price,
                audio_duration: record.audio_duration || initialValues.audio_duration,
                video_duration: record.video_duration || initialValues.video_duration,
                image_duration: record.image_duration || initialValues.image_duration,
                link_data: record.link_data || initialValues.link_data,
                math_ocr_number: record.math_ocr_number || initialValues.math_ocr_number,
                is_active: record.is_active || initialValues.is_active,
                audio_duration_checkbox: record.audio_duration || initialValues.audio_duration,
                video_duration_checkbox: record.video_duration || initialValues.video_duration,
                image_duration_checkbox: record.image_duration || initialValues.image_duration,
                link_data_checkbox: record.link_data || initialValues.link_data,
                math_ocr_number_checkbox: record.math_ocr_number || initialValues.math_ocr_number,
                subscription_description: record.subscription_description || initialValues.subscription_description,
                subscription_uuid: record.subscription_uuid || initialValues.subscription_uuid,
            };

            setInitialValues(values)
        }

    }, [record, setInitialValues]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: () => {
            // console.log('on submit');
        }
    });

    /**
     * Validate accessible content
     * 
     * @returns {boolean}
     */
    const validateAccessibleContent = () => {
        const { values } = formik;

        const checkboxFields = Object.keys(values).filter(field => field.includes('_checkbox'));

        let hasError = false;

        checkboxFields.forEach(field => {

            if (values[field]) {

                const relatedField = field.replace('_checkbox', '');

                const isValid = values[relatedField] && values[relatedField] >= -1;

                if (!isValid) {
                    formik.setFieldError(relatedField, 'Field is incorrect');

                    hasError = hasError !== true ? true : hasError;
                }
            }
        });

        return hasError;
    }; 

    /**
     * Handle submit
     */
    const handleSubmit = useCallback(() => {
        
        const hasError = validateAccessibleContent();

        if (hasError) {
            return;
        }

        formik.submitForm();

        if (formik.isValid) {

            formik.values.subscription_description = formik.values.subscription_description.filter(
                description => description?.content?.trim().length > 0
            );

            const data = {
                name: formik.values.name,
                price: formik.values.price,
                audio_duration: formik.values.audio_duration,
                video_duration: formik.values.video_duration,
                image_duration: formik.values.image_duration,
                link_data: formik.values.link_data,
                math_ocr_number: formik.values.math_ocr_number,
                is_active: formik.values.is_active,
                subscription_description: formik.values.subscription_description || [],
                subscription_uuid: formik.values.subscription_uuid || null,
            };

            if (record.id) {
                dataProvider.update('subscription', {
                    id: record.id,
                    data,
                    previousData: record
                }, {
                    onSuccess: () => {
                        notify('Subscription was updated successful', 'success');
                        history.push('/subscription');
                    },
                    onFailure: () => {
                        notify('Something went wrong', 'error')
                    }
                });
            } else {

                dataProvider.create('subscription', {
                    data,
                }, {
                    onSuccess: () => {
                        notify('Subscription was created successful', 'success');
                        history.push('/subscription');
                    },
                    onFailure: () => {
                        notify('Something went wrong');
                    }
                });
            }
        }
        
    }, [formik, record, dataProvider]);

    /**
     * On checkbox handle
     * 
     * Activate fields depend of checkbox filed
     * 
     * @param {string} name
     * @param {Record<string, unknown>} props
     */
    const onCheckBoxHandler = useCallback((name, props) => {

        const value = props.values[name];

        if (value !== undefined && value !== null) {

            const fieldValue = Number(!value);

            props.setFieldValue(`${name}`, fieldValue);
        
            if (name.includes('_checkbox') && fieldValue === 0) {
                const fieldName = name.replace('_checkbox', '');

                props.setFieldValue(fieldName, fieldValue);
            }
        }

    }, []);

    /**
     * On change is active checkbox
     */
    const onChangeIsActive = useCallback(() => {
        formik.setFieldValue('is_active', !formik.values['is_active']);
    }, [formik]);

    /**
     * On add subscription content add new empty field to list of subscription content
     */
    const onAddSubscriptionContent = useCallback(() => {
        const contents = formik.values['subscription_description'];

        contents.push({content: ''});

        formik.setFieldValue('subscription_description', contents);
        
    }, [formik]);

    /**
     * On delete subscription content
     * 
     * Handle remove content form array
     * 
     * @param {number} i
     */
    const onDeleteSubscriptionContent = useCallback((i) => {

        const contents = formik.values['subscription_description'];

        if (contents[i]) {

            contents.splice(i, 1);

            formik.setFieldValue('subscription_description', contents);
        }

    }, [formik]);

    const initValuesString = JSON.stringify(initialValues);

    const formikValuesString = JSON.stringify(formik.values);

    const toolbar = <SubscriptionToolbar 
        onSubmit={handleSubmit}
        isChanged={initValuesString !== formikValuesString}
    />;

    return (
        <SimpleForm
            toolbar={toolbar}
        >
            <h2>{record?.id ? 'Edit' : 'Add'} subscription</h2>
            <Grid container spacing={3} className={classes.formWidth}>
                <Grid item sm={6}>
                    <form
                        onSubmit={formik.handleSubmit}
                    >
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        name="name"
                                        label="Name"
                                        variant="filled"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        margin="normal"
                                        name="price"
                                        label="Price"
                                        variant="filled"
                                        onChange={formik.handleChange}
                                        value={formik.values.price}
                                        error={formik.touched.price && Boolean(formik.errors.price)}
                                        helperText={formik.touched.price && formik.errors.price}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        name="subscription_uuid"
                                        label="Subscription uuid"
                                        variant="filled"
                                        onChange={formik.handleChange}
                                        value={formik.values.subscription_uuid}
                                        error={formik.touched.subscription_uuid && Boolean(formik.errors.subscription_uuid)}
                                        helperText={formik.touched.subscription_uuid && formik.errors.subscription_uuid}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <h4>
                                    Accessible content
                                </h4>
                            </Grid>
                            <Grid item sm={12}>
                                <Grid container sm={12}>
                                    <Grid item sm={4}>
                                        <h4>Content</h4>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <h4>Duration</h4>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <h4>Unlimited</h4>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <Grid 
                                        container 
                                        sm={12} 
                                        spacing={2}
                                    >
                                        <Grid item sm={4}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                variant="filled"
                                                disabled
                                                value='Audio'
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <TextField
                                                margin="normal"
                                                name="audio_duration"
                                                value={formik.values['audio_duration'] || ''}
                                                variant="filled"
                                                disabled={formik.values['audio_duration_checkbox'] === 0}
                                                onChange={formik.handleChange}
                                                error={Boolean(formik.errors.audio_duration)}
                                                helperText={formik.errors.audio_duration}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className={classes.checkboxLineHeight}>
                                            <Checkbox
                                                checked={formik.values['audio_duration_checkbox'] === 0}
                                                onChange={() => onCheckBoxHandler('audio_duration_checkbox', formik)}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <Grid 
                                        container 
                                        sm={12} 
                                        spacing={2}
                                    >
                                        <Grid item sm={4}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                variant="filled"
                                                disabled
                                                value='Video'
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <TextField
                                                margin="normal"
                                                name="video_duration"
                                                value={formik.values['video_duration'] || ''}
                                                variant="filled"
                                                disabled={formik.values['video_duration_checkbox'] === 0}
                                                onChange={formik.handleChange}
                                                error={Boolean(formik.errors.video_duration)}
                                                helperText={formik.errors.video_duration}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className={classes.checkboxLineHeight}>
                                            <Checkbox 
                                                checked={formik.values['video_duration_checkbox'] === 0}
                                                onChange={() => onCheckBoxHandler('video_duration_checkbox', formik)}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <Grid 
                                        container 
                                        sm={12} 
                                        spacing={2}
                                    >
                                        <Grid item sm={4}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                variant="filled"
                                                disabled
                                                value='Image'
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <TextField
                                                margin="normal"
                                                name="image_duration"
                                                value={formik.values['image_duration'] || ''}
                                                variant="filled"
                                                disabled={formik.values['image_duration_checkbox'] === 0}
                                                onChange={formik.handleChange}
                                                error={Boolean(formik.errors.image_duration)}
                                                helperText={formik.errors.image_duration}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className={classes.checkboxLineHeight}>
                                            <Checkbox
                                                checked={formik.values['image_duration_checkbox'] === 0}
                                                onChange={() => onCheckBoxHandler('image_duration_checkbox', formik)}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <Grid 
                                        container 
                                        sm={12} 
                                        spacing={2}
                                    >
                                        <Grid item sm={4}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                variant="filled"
                                                disabled
                                                value='Link'
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <TextField
                                                margin="normal"
                                                name="link_data"
                                                value={formik.values['link_data'] || ''}
                                                variant="filled"
                                                onChange={formik.handleChange}
                                                disabled={formik.values['link_data_checkbox'] === 0}
                                                error={Boolean(formik.errors.link_data)}
                                                helperText={formik.errors.link_data}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className={classes.checkboxLineHeight}>
                                            <Checkbox
                                                checked={formik.values['link_data_checkbox'] === 0}
                                                onChange={() => onCheckBoxHandler('link_data_checkbox', formik)}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <Grid 
                                        container 
                                        sm={12} 
                                        spacing={2}
                                    >
                                        <Grid item sm={4}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                variant="filled"
                                                disabled
                                                value='Math OCR'
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <TextField
                                                margin="normal"
                                                name="math_ocr_number"
                                                value={formik.values['math_ocr_number'] || ''}
                                                disabled={formik.values['math_ocr_number_checkbox'] === 0}
                                                variant="filled"
                                                onChange={formik.handleChange}
                                                error={Boolean(formik.errors.math_ocr_number)}
                                                helperText={formik.errors.math_ocr_number}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className={classes.checkboxLineHeight}>
                                            <Checkbox
                                                checked={formik.values['math_ocr_number_checkbox'] === 0}
                                                onChange={() => onCheckBoxHandler('math_ocr_number_checkbox', formik)}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <Grid item sm={4} className={classes.checkboxLineHeight}>
                                        <FormControlLabel 
                                            label="Is active" 
                                            control={<Checkbox/>} 
                                            onChange={onChangeIsActive}
                                            checked={Boolean(formik.values['is_active'])}
                                        />
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <h4>Subscription content</h4>
                            </Grid>
                            {formik.values['subscription_description'].map((subscription, i) => {
                                return (
                                    <Grid item sm={12} key={i}>
                                        <FormGroup>
                                            <Grid container spacing={3}>
                                                <Grid item sm={10}>
                                                <TextField
                                                    margin='normal'
                                                    name={`subscription_description.${i}.content`}
                                                    variant='filled'
                                                    value={subscription.content}
                                                    onChange={formik.handleChange}
                                                    error={formik?.errors?.subscription_description && formik?.errors?.subscription_description[i]?.content ? true : false}
                                                    helperText={formik?.errors?.subscription_description ? formik?.errors?.subscription_description[i]?.content : ``}
                                                    fullWidth={true}
                                                />
                                                </Grid>
                                                <Grid item sm={2} className={classes.checkboxLineHeight}>
                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        onClick={() => onDeleteSubscriptionContent(i)}
                                                    >Delete</Button>
                                                </Grid>
                                            </Grid>
                                        </FormGroup>
                                    </Grid>
                                );
                            })}
                            <Grid item sm={12}>
                                <Button 
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => onAddSubscriptionContent()}
                                >Add</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </SimpleForm> 
    );
}