import { useState, useEffect } from 'react';
import { Notification, useNotify } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress,
         Card,
         Typography,
         TextField,
         CardActions,
         Button } from '@material-ui/core';

import { API_URL, DEEP_LINK_URL } from '../../../constants/env';
import { isMobile, isTablet } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#9e9e9e',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
  },
  card: {
      minWidth: 550,
      marginTop: '-10em',
      border: '2px solid',
      borderColor: '#4f3cc9',
  },
  form: {
      padding: '0 3em 1em 3em',
  },
  input: {
      marginTop: '1em',
  },
  actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      padding: '1em 3em 1em 3em',
  },
  button: {
      width: '50%',
  },
  headings: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '1em 3em 1em 3em',
  },
  middleTitle: {
      margin: '1em 0 1em 0',
  },
  reset: {
      borderRadius: '0px',
      marginTop: '20em',
      padding: '2em'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => {
  return <TextField
              error={!!(touched && error)}
              helperText={touched && error}
              {...inputProps}
              {...props}
              fullWidth
          />
};

const ResetPassword = ({ history }) => {
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState('name@email.com');
  const location = useLocation();
  const classes = useStyles();
  const notify = useNotify();
  const token = new URLSearchParams(location.search).get('token');

  if (isMobile || isTablet) {
    const url = window.location.href;
 
    const redirect = url.replace(window.location.origin, DEEP_LINK_URL);
  
    window.location.href = redirect;
  }

  const validateToken = async token => {
    try {
      const response = await fetch(`${API_URL}/password_reset/validate_token/`, {
        method: 'POST',
        body: `token=${token}`,
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      });
      const json = await response.json();
      
      if(response.status !== 200) {
        throw new Error();
      } else {
        setMail(json.email);
        setIsValid(true);
      }
    } catch (e) {
      history.replace('/login');
    } 
  };

  const validateFields = values => {
    const errors = {};
    if (!values.passOne) {
      errors.passOne = 'Password field can\'t be empty';
    }
    if (!values.passTwo) {
      errors.passTwo = 'Password field can\'t be empty';
    }
    return errors;
  };

  const handleSubmit = async values => {
    const re = /^[(?=.*\w+)(?=.*\d+)]{6,}$/;
    if (values.passOne !== values.passTwo) {
      notify('Please make sure your passwords match', 'warning')
    } else if (!re.test(values.passOne) || !re.test(values.passTwo)) {
      notify('Password must contain at least 6 characters including numbers', 'warning');
    } else {
      setLoading(true);
      try {
        const data = new FormData();
        data.set('password', values.passOne);
        data.set('token', token);
        const response = await fetch(`${API_URL}/password_reset/confirm/`, {
          method: 'POST',
          body: data,
        });
        const json = await response.json();
        if (response.status === 200) {
          history.replace('/login');
        } else {
          throw new Error(json.detail);
        }
      } catch (e) {
        notify(e.message, 'warning');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    validateToken(token);
  }, [token]);

  const loader = <CircularProgress size={100} thickness={2} />

  return (
    <div className={classes.main}>
      {!isValid ? loader : (
        <Form
          onSubmit={handleSubmit}
          validate={validateFields}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Card className={classes.card}>
                  <div className={classes.headings}>
                    <Typography variant="h4" component="div">
                      uLearn
                    </Typography>
                    <Typography variant="h6" component="div" className={classes.middleTitle}>
                      Set new password
                    </Typography>
                    <Typography component="p">
                      Please enter below the new password for {mail}
                    </Typography>                  
                  </div>
                  <div className={classes.form}>
                    <div className={classes.input}>
                      <Field
                        name="passOne"
                        component={renderInput}
                        label="New Password"
                        disabled={loading}
                        type="password"
                      />
                      <Field
                        name="passTwo"
                        component={renderInput}
                        label="Confirm Password"
                        disabled={loading}
                        type="password"
                      />
                    </div>
                  </div>
                  <CardActions className={classes.actions}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={loading}
                      className={classes.button}
                    >
                      Save
                    </Button>
                  </CardActions>
                </Card>
                <Notification />
              </form>
            );
          }}
        />
      )}
    </div>
  )
};

export default ResetPassword;