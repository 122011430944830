import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Toolbar, Button } from 'react-admin';
import { makeStyles  } from '@material-ui/core/styles';
import { 
    Dialog,
    DialogActions,
    DialogContent, 
    DialogTitle,
    DialogContentText,
} from '@material-ui/core';

const useToolbarStyles = makeStyles(theme => ({
  bar: {
    display: 'flex',
    justifyContent: 'center',
  },
  cancel: {
    marginRight: '20px',
  }
}));

export const SubscriptionToolbar = ({
    touched,
    goto,
    onSubmit,
    isChanged = false,
    ...props
}) => {

    const [open, setOpen] = useState(false);

    const classes = useToolbarStyles();
    const history = useHistory();

    const onGoBack = useCallback(() => {

        if (isChanged) {
            setOpen(true);
        } else {
            history.push('/subscription');
        }

    }, [history, isChanged, setOpen]);

    const handleSave = useCallback(() => {
        onSubmit();
    }, [onSubmit]);

    const handleCloseDialog = useCallback(() => {
        
        setOpen(false);
    }, []);

    const handleRedirect = useCallback(() => {

        history.push('/subscription');

    }, [history])

    return (
        <Toolbar {...props} className={classes.bar}>
            <Button 
                label='Cancel' 
                variant='contained' 
                size='medium'
                className={classes.cancel}
                onClick={onGoBack}
            />
            <Button
                variant='contained'
                size='medium'
                label='Save'
                onClick={handleSave}
            />
            <Dialog
                open={open}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    You have some unsaved changes. Are you sure you want to quit this page?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        color='primary'
                        label='Stay'
                    />
                    <Button
                        autoFocus
                        onClick={handleRedirect}
                        color='redirect'
                        label='Leave'
                    />
                </DialogActions>
            </Dialog>
        </Toolbar>
    );
}
