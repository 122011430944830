import { List, Datagrid, TextField, Pagination, useRecordContext, 
         TextInput, FunctionField,
         ShowButton,
         DeleteWithConfirmButton, EmailField, NumberField, Edit } from 'react-admin';
import { Tooltip } from '@material-ui/core';
import { makeStyles  } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import CustomHeader from '../../layout/CustomHeader';
import { EditForm } from './EditForm';


const UsersPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const useStyles = makeStyles(theme => ({
  main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#9e9e9e',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const ActionsField = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);

  return (
    <div className={classes.actions}>
      <Tooltip title="Edit">
        <div>
        <ShowButton basepath='/users' record={record} label="">
          <CreateIcon />
        </ShowButton>
        </div>
      </Tooltip>
      <Tooltip title="Delete">
        <div>
          <DeleteWithConfirmButton 
            record={record} 
            label=""
            confirmTitle="Delete user"
            confirmContent="Are you sure you want to delete the user?"
            undoable={false}
          />
        </div>
      </Tooltip>
    </div>
  );
}

const usersFilter = [
  <TextInput source="first_name" label="First name" alwaysOn />,
  <TextInput source="last_name" label="Last name" alwaysOn />,
  <TextInput source="email" label="Email" alwaysOn />
];

export const UsersList = props => {
  const columns = {
    first_name: {
      width: '15%',
    },
    last_name: {
      width: '15%',
    },
    email: {
      width: '20%',
    },
    'subscription_name': {
      width: '10%'
    },
    qr_codes_scanned: {
      width: '10%',
    },
    is_active: {
      width: '10%'
    },
    actions: {
      width: '15%',
      align: 'center'
    },
  };

  return (
    <List 
      pagination={<UsersPagination />} 
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      exporter={false}
      filters={usersFilter}
      {...props} >
        <Datagrid header={<CustomHeader columns={columns}/>} >
          <TextField source="first_name" label="First Name" sortable />
          <TextField source="last_name" label="Last Name" sortable />
          <EmailField source="email" label="Email" />
          <TextField source="subscription_name" label="Subscription" emptyText='-'/>
          <NumberField textAlign='left' source="qr_codes_scanned" label="QR codes scanned" emptyText='-'/>
          <FunctionField 
            source="is_active"
            label="Status"
            render={(record) => `${record?.is_active ? 'Active' : 'Restricted'}`} 
          />
          <ActionsField source="actions" sortable={false}/>
        </Datagrid>
    </List>
  );
};

export const UsersEdit = (props) => {

  return (
    <Edit {...props}>
      <EditForm {...props}/>
    </Edit>
  );
};

export default UsersList;