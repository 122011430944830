import { useCallback, useState, useEffect } from 'react';
import {
    SimpleForm,
    useDataProvider,
    useNotify,
    useGetList,
} from 'react-admin';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
    TextField,
    Grid,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PromoCodeToolbar } from './Toolbar';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    checkboxLineHeight: {
        lineHeight: 5,
    },
    formWidth: {
        width: '100%'
    },
    dateField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export const PromoCodeForm = (props) => {

    const record = props.record;

    const classes = useStyles();
    
    const dataProvider = useDataProvider();
    
    const notify = useNotify();

    const history = useHistory();

    const [initialValues, setInitialValues] = useState({
        code: '',
        name: '',
        subscription_id: 'all',
        platform: null,
        discount_percentage: null,
        allowed_usages: null,
        already_used: null,
        date_start: null,
        date_end: null,
        status: 'pending'
    });

    const validationSchema = yup.object().shape({
        code: yup.string()
            .trim()
            .required(),
        name: yup.string()
            .trim()
            .required(),
        platform: yup.mixed()
            .required(),
        discount_percentage: yup.number()
            .required('Discount percentage is a required field')
            .min(0)
            .max(100),
        allowed_usages: yup.number()
            .min(0)
            .required('Allowed usages is a required field'),
        date_start: yup.string()
            .required('Start date is a required field'),
        date_end: yup.string()
            .required('End date is a required field')        
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: () => {},
    });

    useEffect(() => {

        if (record.id) {

            const data = {
                ...record,
                subscription_id: record?.subscription_id || 'all'
            }

            setInitialValues(data);
        }

    }, [record]);

    const handleSubmit = useCallback(() => {

        const data = formik.values;

        const dateStartTime = new Date(data.date_start).getTime();
        const dateEndTime = new Date(data.date_end).getTime();

        let hasError = false;

        const currentDate = new Date();

        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setMilliseconds(0);

        const currentDateTime = currentDate.getTime();

        if (dateStartTime < currentDateTime) {

            hasError = true;
        
            notify('Date start can not be past', 'error');
        }

        if (dateStartTime > dateEndTime && !hasError) {

            hasError = true;

            notify('Date start can not be more than date end', 'error');
        }

        if (record?.id && !hasError && record?.already_used > 0 && record?.discount_percentage !== data.discount_percentage) {
            
            hasError = true;

            notify('You can not change discount percentage for already used promo code', 'error');

        }

        if (record?.id && !hasError && record?.already_used > 0 && record?.subscription_id !== data.subscription_id) {

            hasError = true;

            notify('You can not change subscription for already used promo code', 'error');
        }

        if (hasError) {
            return;
        }

        formik.submitForm();

        if (formik.isValid) {

            hasError = false;

            data.discount_percentage = Number(data.discount_percentage).toFixed(2)

            if (data.subscription_id === 'all') {
                data.subscription_id = null;
            }

            if (record.id) {

                dataProvider.update('promocode', {
                    id: record.id,
                    data,
                    previousData: record,
                }, {
                    onSuccess: () => {
                        notify('Voucher was update successful', 'success');
                        history.push('/promocode');
                    },
                    onFailure: (error) => {

                        notify('Something went wrong', 'error');
                    }
                });
            } else {

                dataProvider.create('promocode', {
                    data: {
                        ...data,
                        already_used: 0,
                    }
                }, {
                    onSuccess: () => {
                        notify('Voucher was created successful', 'success');
                        history.push('/promocode');
                    },
                    onFailure: () => {
                        notify('Something went wrong', 'error');
                    }
                })
            }
        }

    }, [formik, record, notify,  dataProvider, history]);

    const { data: subscriptionsObj } = useGetList('subscription', {}, {}, {nopager: 1});

    const subscriptions = Object.values(subscriptionsObj) || [];

    const platforms = [
        {
            id: 'ios',
            name: 'iOS'
        },
        {
            id: 'android',
            name: 'Android',
        }
    ];

    const statuses = [
        { id: 'pending', name: 'Pending' },
        { id: 'active', name: 'Active' },
        { id: 'completed', name: 'Completed' },
        { id: 'expired', name: 'Expired' },
        { id: 'suspended', name: 'Suspended' },
    ];

    const toolbar = <PromoCodeToolbar
        onSubmit={handleSubmit}
    />

    /**
     * Handle change select
     */
    const handleChangeSelect = useCallback((value, name) => {
        formik.setFieldValue(name, value);
    }, [formik]);

    /**
     * Handle change date
     */
    const handleChangeDate = useCallback((value, name) => {
        formik.setFieldValue(name, value);
    }, [formik]);

    return (
        <SimpleForm toolbar={toolbar}>
            <h2>{record?.id ? 'Edit' : 'Add'} voucher</h2>
            <Grid container spacing={3} className={classes.formWidth}>
                <Grid item sm={6}>
                    <form
                        onSubmit={formik.handleSubmit}
                    >
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        margin='normal'
                                        name='code'
                                        label='Code'
                                        variant='filled'
                                        onChange={formik.handleChange}
                                        value={formik.values.code}
                                        error={formik.touched.code && Boolean(formik.errors.code)}
                                        helperText={formik.touched.code && formik.errors.code}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        margin='normal'
                                        name='name'
                                        label='Name'
                                        variant='filled'
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <FormControl
                                        fullWidth
                                        error={formik.touched.subscription && formik.errors.subscription}
                                    >
                                        <InputLabel
                                            id='subscription-select-field'
                                        >Subscription</InputLabel>
                                        <Select
                                            labelId='subscription-select-field'
                                            id='subscription-select'
                                            value={formik.values.subscription_id || 'all'}
                                            label='Subscription'
                                            onChange={(e) => handleChangeSelect(e.target.value, 'subscription_id')}
                                        >
                                             <MenuItem value='all'>All</MenuItem>
                                            {subscriptions && subscriptions?.map((subscription, i) => {
                                                return (
                                                    <MenuItem key={i} value={subscription.id}>{subscription.name}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText>{formik.errors.subscription_id}</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <FormControl
                                        fullWidth
                                        error={formik.touched.platform && formik.errors.platform}
                                    >
                                        <InputLabel
                                            id='platform-select-field'
                                        >Platform</InputLabel>
                                        <Select
                                            labelId='platform-select-field'
                                            id='platform-select'
                                            value={formik.values.platform || ''}
                                            label='Platform'
                                            onChange={(e) => handleChangeSelect(e.target.value, 'platform')}
                                        >
                                            <MenuItem value=''>Select platform</MenuItem>
                                            {platforms && platforms?.map((platform, i) => {

                                                return (
                                                    <MenuItem  
                                                        key={i} 
                                                        value={platform.id}
                                                    >{platform.name}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText>{formik.errors.platform}</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <TextField
                                        type='number'
                                        fullWidth
                                        margin='normal'
                                        name='discount_percentage'
                                        label='Percentage discount'
                                        variant='filled'
                                        onChange={formik.handleChange}
                                        value={formik.values.discount_percentage}
                                        error={formik.touched.discount_percentage && Boolean(formik.errors.discount_percentage)}
                                        helperText={formik.touched.discount_percentage && formik.errors.discount_percentage}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <TextField
                                        type='number'
                                        fullWidth
                                        margin='normal'
                                        name='allowed_usages'
                                        label='Allowed usage number'
                                        variant='filled'
                                        onChange={formik.handleChange}
                                        value={formik.values.allowed_usages}
                                        error={formik.touched.allowed_usages && Boolean(formik.errors.allowed_usages)}
                                        helperText={formik.touched.allowed_usages && formik.errors.allowed_usages}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <TextField
                                        id="date"
                                        label="Start Date"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={formik.touched.date_end && Boolean(formik.errors.date_end)}
                                        onChange={(e) => handleChangeDate(e.target.value, 'date_start')}
                                        value={formik.values.date_start}
                                        helperText={formik.touched.date_start && formik.errors.date_start}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <TextField
                                        id="date"
                                        label="End Date"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={formik.touched.date_end && Boolean(formik.errors.date_end)}
                                        onChange={(e) => handleChangeDate(e.target.value, 'date_end')}
                                        value={formik.values.date_end}
                                        helperText={formik.touched.date_end && formik.errors.date_end}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <FormGroup>
                                    <FormControl
                                        fullWidth
                                    >
                                        <InputLabel
                                            id='status-select-field'
                                        >Status</InputLabel>
                                        <Select
                                            labelId='status-select-field'
                                            id='status-select'
                                            value={formik.values.status}
                                            label='Status'
                                            onChange={(e) => handleChangeSelect(e.target.value, 'status')}
                                        >
                                            {statuses && statuses?.map((status, i) => {
                                                return (
                                                    <MenuItem key={i} value={status.id}>{status.name}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </SimpleForm>
    );
};