import React, { useCallback } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    maxLength,
    useDataProvider,
    useNotify
} from 'react-admin';
import { makeStyles  } from '@material-ui/core/styles';
import { UserFormToolbar } from './UserFormToolbar';
import { NonInput } from '../../../utils';
import { useHistory } from 'react-router';


const useStyles = makeStyles(theme => ({
    emailField: {
      width: '50%',
    },
    inlineFieldContainer: {
        width: '50%'
    },
    inlineField: {
        marginRight: 20
    },
    title: {
      fontSize: '1.4em',
      fontWeight: 'bold',
    },
    otherData: {
        listStyle: 'none',
        paddingLeft: 0,
        '& > li': {
            paddingBottom: 15
        }
    }
  }));
  

const CategoryEditTitle = props => (
    <span>
      {props.record ? props.record.name : 'View user'}
    </span>
);

const validateEmail = [
    required('Please, enter the email'),
    maxLength(100, 'Email should be less than 100 symbols')
];

export const EditForm = (props) => {

    const classes = useStyles();

    const { record } = props;

    const history = useHistory();

    const notify = useNotify();

    const dataProvider = useDataProvider();

    const onRestrict = useCallback((value) => {

        if (value) {

            const formData = new FormData();

            formData.append('first_name', record.first_name);
            formData.append('last_name', record.last_name);
            formData.append('username', record.username);
            formData.append('is_active', !record.is_active);

            dataProvider.update('users', {
                id: record.id,
                data: formData,
                previousData: record,
            }, {
                onSuccess: () => {
                    notify('User was restricted successful', 'success');
                    history.push('/users');
                },
                onFailure: () => {
                    notify('Something went wrong', 'error');
                }
            });
        }
    }, [dataProvider, record, notify]);

    const toolbar = <UserFormToolbar 
        goto="/users"
        question="You have some unsaved data. Are you sure you want to proceed?"
        confirm="save"
        cancel='Back'
        onRestrict={onRestrict}
        isActive={record.is_active || false}
    />;

    return (
        <Edit {...props} title={<CategoryEditTitle/>}>
            <SimpleForm toolbar={toolbar}>
                <NonInput>
                    <p className={classes.title}>View User</p> 
                </NonInput>
                <div className={classes.inlineFieldContainer}>
                    <TextInput 
                        source="first_name"
                        label="First name"
                        className={classes.inlineField}
                        disabled
                        validate={[
                            maxLength(10, 'First name should be less than 100 symbols')
                        ]}
                    />
                    <TextInput 
                        source="last_name"
                        label="Last name"
                        className={classes.inlineField}
                        disabled
                        validate={[
                            maxLength(10, 'Last name should be less than 100 symbols')
                        ]}
                    />
                </div>
                <TextInput 
                    source="email" 
                    label="email" 
                    className={classes.emailField}
                    disabled 
                    validate={validateEmail}
                />
                <div>
                    <h2>Other data</h2>
                    <ul className={classes.otherData}>
                        <li>Subscription: {record?.subscription_name ? `${record.subscription_name} (${record.subscription_price} NGN)` : '-'}</li>
                        <li>Joined: {record?.date_joined 
                            ? new Date(record.date_joined).toISOString().slice(0, 10).replaceAll('-', '.') 
                            : '-'}</li>
                        <li>QR codes scan: {record?.qr_codes_scanned || 0}</li>
                    </ul>
                </div>
            </SimpleForm>
        </Edit>
    );
}