import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';
import { getPromoCodeUsage } from './../../../providers/dataProvider';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    listContainer: {
        padding: '12px 16px',
    },
    table: {
        minWidth: 650,
    },
}));

export const PromoCodeUsage = (props) => {

    const record = props.record;

    const classes = useStyles();

    const dataProvider = useDataProvider();

    const [list, setList] = useState(null);

    useEffect(() => {

        getPromoCodeUsage(record.code).then(res => {
            
            if (res?.data.length) {
                setList(res.data);
            }
        });


    }, [dataProvider, setList, record]);

    return (
        <div className={classes.listContainer}>
            <h3>{record.code} voucher used</h3>

            {(!list || list?.length === 0) && <div>
                Not used yet    
            </div>}
            {list?.length && 
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Code</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Username</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {list?.length && list.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell align="center">{row.code}</TableCell>
                                <TableCell align="center">{row.date}</TableCell>
                                <TableCell align="center">{row.username}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    );
};