import { Edit, SimpleForm, TextInput, NumberInput, 
         ImageInput, ImageField, required,
         maxLength, useEditController, useGetList,
         FileInput, FileField, Button } from 'react-admin';

import { useState, useEffect, useRef } from 'react'; 

import { makeStyles  } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import CancelToolbar from '../../layout/CancelToolbar';
import { CodeInput } from './CodeInput';
import { types } from '../../../constants';
import { API_URL } from '../../../constants/env';
import { getStorage, NonInput } from '../../../utils';
import BookmarksIcon from '@material-ui/icons/Bookmarks';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
  },
  description: {
    width: '50%',
  },
  cover: {
    width: '30%',
  },
  qr: {
    minWidth: '160px',
    width: '5%',
  },
  name: {
    width: '50%'
  },
  title: {
    fontSize: '1.4em',
    fontWeight: 'bold',
  },
  load: {
    width: '180px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  first_chapter_html: {
    maxWidth: '300px'
  }
}));


const formatLogo = value => {
  if (!value || typeof value === 'string') {
    return { url: value };
  }
  return value;
}

const BookEditTitle = props => (
  <span>
    {props.record ? props.record.name : 'Change book'}
  </span>
);

const validateName = required('Please, enter the Name of the book');
const validateDescription = maxLength(500, 'Description should be less than 500 symbols');

const BookEdit = props => {
  const classes = useStyles();
  const editProps = useEditController(props);
  const [content, setContent] = useState(editProps.record?.['book_bookcontent']);
  const [deleted, setDeleted] = useState(false);
  const deletedContent = useRef([]);
  const id = editProps.record?.id;

  if (editProps?.record?.first_chapter_html && !editProps.record?.first_chapter_html?.url) {

    const chapterLink = editProps.record?.first_chapter_html;

    editProps.record.first_chapter_html = {
      url: chapterLink,
      title: chapterLink,
    };
  }

  useEffect(() => {
    
    if (!content) {

      setContent(editProps.record?.['book_bookcontent']);
    }
  }, [editProps, content]);

  const { data: categoriesObj } = useGetList('content_categories', {}, {}, { nopager: '1' });
  const { data: subscriptionsObj } = useGetList('subscription', {}, {}, { nopager: 1 });
  const categories = Object.values(categoriesObj);
  const subscriptions = Object.values(subscriptionsObj);

  const toolbar = <CancelToolbar 
    goto="/books"
    question="Are you sure, you want to cancel editing the book?"
    touched={deleted}/>;

  const formatData = payload => {
    const data = {};
    const checked = {};
    const form = {};
    const formData = new FormData();
    data['content_number'] = payload.content_number;
    data['id'] = id;
    const bookContent = [];

    for (const key in payload) {
      const index = key.indexOf('-id');
      const id = key.slice(0, index);
      if (index > 0 && !(id in checked)) {
        checked[id] = [];
        const qrObject = {
          id: +id,
        }
        for (const key in payload) {
          if (key.startsWith(id)) {
            if (key.includes('chapter')) {
              qrObject.chapter = +payload[key];
              continue;
            }
            if (key.includes('page')) {
              qrObject.page = +payload[key];
              continue;
            }
            if (key.includes('category')) {
              qrObject['content_category'] = payload[key] ? +payload[key] : null;
              continue;
            }
            if (key.includes('qrtitle')) {
              qrObject.description = payload[key];
              continue;
            }

            if (key.includes('file')) {
              if (!qrObject['bookscontent_file']) {
                qrObject['bookscontent_file'] = [];
              }
              const filePos = key.indexOf('-file-') + 6;
              const fileIndex = key.charAt(filePos);
              if (!checked[id].includes(fileIndex)) {
                checked[id].push(fileIndex);
                const fileObj = {};
                let fileFlag = true;
                for (const key in payload) {
                  if (key.startsWith(`${id}-id-file-${fileIndex}`)) {
                    if (key.includes('title')) {
                      fileObj.label = payload[key];
                      continue;
                    }
                    if (key.includes('type') && !key.includes('subscription')) {
                      fileObj.type = payload[key];
                      continue;
                    }
                    if (key.includes('subscription_type')) {
                      fileObj.subscription_type = payload[key];
                      continue;
                    }
                    if (key.includes('link')) {
                      fileObj.link = payload[key];
                      continue;
                    }
                    if (key.includes('form')) {
                      if (payload[key].rawFile) {
                        form[key] = payload[key];
                      }
                      continue;
                    }
                    if (key.includes('status')) {
                      fileFlag = payload[key];
                    }
                    if (key.includes('contentid')) {
                      fileObj.id = payload[key];
                      continue;
                    }
                  }
                }
                if (fileFlag) {
                  qrObject['bookscontent_file'].push(fileObj);
                }
              }
            }
          }
        }
        if (!deletedContent.current.includes(qrObject.id)) {
          bookContent.push(qrObject);
        }
      } else if (index < 0) {
        if (key === 'cover') {
          if (!payload.cover) {
            formData.append('cover', '');
          } else if (typeof payload.cover !== 'string') {
            formData.append('cover', payload.cover.rawFile);
          }
        } else {
          data[key] = payload[key];
        }
      }      
    }
    data['book_bookcontent'] = bookContent;
    for (const key in form) {
      formData.append(key, form[key].rawFile);
    }

    if (data['first_chapter_html'] && data['first_chapter_html']['rawFile']) {
      formData.append('first_chapter_html', data['first_chapter_html'].rawFile);
      delete data['first_chapter_html'];
    } else {
      delete data['first_chapter_html'];
    }
    
    formData.append('json', JSON.stringify(data));
    return formData;
  }

  const deleteCode = id => {
    const newCodes = content.filter(e => e.id !== id);
    deletedContent.current.push(id);
    setContent(newCodes);
    setDeleted(true);
  }

  const loadMore = async () => {
    try {
      const storage = getStorage();
      const token = storage.getItem('token');
      const response = await fetch(`${API_URL}/admin/books/${id}/add_one_more_content/`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      const json = await response.json();
      setContent([...content, json]);
    } catch (e) {
      console.log(e);
    }
  }

  const downloadZip = async () => {
    try {
      const storage = getStorage();
      const token = storage.getItem('token');
      const response = await fetch(`${API_URL}/admin/books/${id}/download_zip/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `qr_codes_for_bookid_${id}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.log(e);
    } 
  }

  return (
    <Edit {...props} 
      title={<BookEditTitle />} 
      transform={formatData}
      successMessage="Book is updated">
      <SimpleForm toolbar={toolbar}>
        <NonInput>
          <div className={classes.header}>
            <p className={classes.title}>Edit Book</p>
            <Button
              label="Download the file with QR codes"
              onClick={() => downloadZip()}
            />
          </div>
        </NonInput>
        <ImageInput 
          format={ formatLogo }
          source="cover" 
          label="Book cover"
          maxSize={5000000} 
          accept="image/*"
          className={classes.cover}>
          <ImageField source="url" />
        </ImageInput>
        <div className={classes.container}>
        <FileInput
          source='first_chapter_html'
          placeholder='Select a File'
          label="First chapter html"
          className={classes['first_chapter_html']}
          accept='text/html'
        >
          <FileField source='url' title='title'/>
        </FileInput>
        </div>
        <div className={classes.container}>
          <TextInput 
            source="name" 
            label="Name of the book" 
            className={classes.name} 
            validate={validateName} />
          <NumberInput 
            source="content_number"
            label="Number of QR codes" 
            className={classes.qr} 
            disabled />
        </div>
        <TextInput 
          source="description" 
          multiline
          validate={validateDescription} 
          className={classes.description}
        />
        {  content?.length ? <div>
          { content.map((e, i) => {

            const diffChapter = i > 0 && e?.chapter !== content[i - 1]?.chapter ? true : false;

            return (
              <>
                {diffChapter ? <>
                  <BookmarksIcon fontSize='large'/>
                  <br/>
                </> :  ''}
                <CodeInput 
                  key={e.id}
                  imgLink={e.qr_code_file}
                  id={e.id}
                  category={e.content_category}
                  page={e.page}
                  title={e.description}
                  files={e.bookscontent_file}
                  edit
                  chapter={e.chapter}
                  onDelete={deleteCode}
                  categories={categories}
                  types={types}
                  subs={subscriptions}
                />
              </>
            );
          })}
          </div> : ''
        } 
        <Button 
          label="Add more"
          className={classes.load}
          size="large"
          onClick={() => loadMore()}>
          <AddCircleOutlineIcon />
        </Button>
      </SimpleForm>
    </Edit>
  );
}

export default BookEdit;