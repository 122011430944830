import { fetchUtils, HttpError, downloadCSV } from 'react-admin';
import { stringify } from 'query-string';
import { API_URL } from '../constants/env';
import { getStorage } from '../utils';

const api = API_URL + '/admin';

const httpClient = fetchUtils.fetchJson;

const getOptions = () => {
  const storage = getStorage();
  return {
    user: {
      authenticated: true,
      token: `Bearer ${storage.getItem('token')}`,
    }
  };
};

export const customExport = (resource) => {

  httpClient(`${api}/${resource}`, getOptions())
    .then((csv) => {
      downloadCSV(csv.body, 'payments')
    })
    .catch(err => {
      console.err(err);
    });
}

export const getPromoCodeUsage = (name) => {

  return httpClient(`${api}/promocodeusage?promocode=${name}`, getOptions())
    .then(({json}) => {
      return ({
        data: json,
      });
    });
}

const provider = {
  getList: (resource, params) => {

    const { page } = params.pagination;
    const { field, order } = params.sort;

    const sortSign = order === 'ASC' ? '' : '-';
    const nameContains = params.filter.name ?? '';

    const query = {
      ordering: sortSign + field,
      page: page,
      name__icontains: nameContains,
      timestamp: Date.now(),
    };
    
    if(params.filter.nopager) {
      query['nopager'] = '1';
    }

    if (params.filter?.first_name) {

      query['first_name__icontains'] = params.filter.first_name;
    }

    if (params.filter?.last_name) {

      query['last_name__icontains'] = params.filter.last_name;
    }

    if (params.filter?.email) {

      query['email__icontains'] = params.filter.email;
    }

    if (params.filter?.testclass__name__icontains) {
      query['testclass__name__icontains'] = params.filter.testclass__name__icontains;
    }

    const url = `${api}/${resource}?${stringify(query)}`;

    return httpClient(url, getOptions()).then(({ headers, json }) => {

      return ({
        data: json,
        total: params.filter.nopager ? json.length
          : parseInt(headers.get('content-range').split('/').pop(), 10),
      });
    })
  },

  getOne: (resource, params) =>
    httpClient(`${api}/${resource}/${params.id}`, getOptions()).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${api}/${resource}?${stringify(query)}`;
    return httpClient(url, getOptions()).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page,  } = params.pagination;
    const { field, order } = params.sort;

    const sortSign = order === 'ASC' ? '' : '-';

    const query = {
      ordering: sortSign + field,
      page: page,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${api}/${resource}?${stringify(query)}`;

    return httpClient(url, getOptions()).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  update: (resource, params) => {

    let body = JSON.stringify(params.data);

    if (params.data instanceof FormData) {
      body = params.data
    }
	
    return httpClient(`${api}/${resource}/${params.id}/`, {
      method: 'PATCH',
      body: body,
      user: getOptions().user,
    }).then(({ json }) => {
      return {
        data: json
      }
    }).catch(err => {

      if (err?.body?.error) {
        throw(new HttpError(err.body.error));
      } else {

        if (err.body && resource === 'content_categories') {
          throw new Error('Content category with this name already exists');
        } else {
          throw new Error('Something went terribly wrong');
        }
      }
    });
  },
  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${api}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      user: getOptions().user,
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
		let body = JSON.stringify(params.data);

    if (resource === 'books') {
      const formData = new FormData();
      for (const param in params.data) {
				if (param === 'cover' && params.data[param]?.rawFile) {
          formData.append('cover', params.data[param].rawFile);
					continue;
				} else if (param !== 'cover') {
          formData.append(param, params.data[param]);
        }
				
      }
			body = formData;
    }

    return httpClient(`${api}/${resource}/`, {
      method: 'POST',
      body: body,
      user: getOptions().user,
    }).then(({ json }) =>  {
      return {
        data: { ...json },
      }
    })
    .catch(err => {
      if (resource === 'content_categories') {
        throw new Error('Content category with this name already exists');
      } else {
        throw new Error('Something went terribly wrong');
      }
      
    })
  },
  delete: (resource, params) => {
    return httpClient(`${api}/${resource}/${params.id}`, {
      method: 'DELETE',
      user: getOptions().user,
    }).then(({ json }) => {
      return { data: json };
    }).catch(err => {
      // if (resource === 'content_categories') {
      if (err?.body?.error) {
        throw new Error(err.body.error);
      } else {
        throw new Error('something went terribly wrong');
      }
        
    })
  },
  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${api}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
      user: getOptions().user,
    }).then(({ json }) => ({ data: json }));
  },
};

const addUserProfileOverrides = (dataProvider) => ({
  ...dataProvider,
  getUserProfile() {
    const profile = localStorage.getItem("user");
    if (!profile) {
      return Promise.resolve({ data: {} });
    }
    const data = JSON.parse(profile);
    return Promise.resolve({ data });
  },
  async updateUserProfile({ data }) {
    try {
      const fd = new FormData();
      fd.append('email', data.email);
      fd.append('username', data.fullName)
      if (data.avatar.rawFile) {
        fd.append('image', data.avatar.rawFile);
      }
      const result = await httpClient(`${api}/profile/${data.id}/update_profile/`, {
        method: 'PATCH',
        body: fd,
        user: getOptions().user
      });
      const json = result.json;
      localStorage.setItem(
        "user",
        JSON.stringify({
          email: json.email,
          avatar: json.profile.image,
          fullName: json.username,
          id: json.id,
        })
      );
      return Promise.resolve({ data });
    } catch (e) {
      return Promise.reject({ data })
    }
  }
});



export default addUserProfileOverrides(provider);