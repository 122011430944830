import {
    List, 
    Datagrid,
    Pagination,
    CreateButton,
    EditButton,
    TextField,
    FunctionField,
    useRecordContext,
    TopToolbar,
    TextInput,
    Edit,
    Create,
    Button,
    useDataProvider,
    useNotify,
    useRefresh,
} from 'react-admin';
import { useState, useCallback } from 'react';
import CustomHeader from '../../layout/CustomHeader';
import { makeStyles  } from '@material-ui/core/styles';
import { 
    Tooltip, 
    Dialog, 
    DialogTitle,
    DialogContent, 
    DialogActions, 
    Button as MButton
} from '@material-ui/core';
import { SubscriptionForm } from './SubscriptionForm';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    actions: {
      display: 'flex',
      justifyContent: 'center',
    }
}));

const SubscriptionPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const SubscriptionListActions = () => {
    return (
      <TopToolbar>
        <CreateButton label="Add new subscription" />
      </TopToolbar>
    );
};

const subscriptionFilter = [
    <TextInput source='name' label='Name' alwaysOn/>
];

const ActionField = (props) => {

    const classes = useStyles();
    
    const record = useRecordContext(props);

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const refresh = useRefresh();

    const { onTriggerDialog } = props; 

    const onDelete = useCallback(() => {

        if (record?.is_used_before && record?.is_used_now) {
            onTriggerDialog(true)
        } else {

            dataProvider.delete('subscription', {
                id: record.id
            }, {
                onSuccess: () => {
                    notify('Subscription was deleted successful', 'success');
                    refresh();
                },
                onFailure: () => {
                    notify('Something went wrong', 'error');
                }
            });
        }
        
    }, [onTriggerDialog, record, dataProvider, notify, refresh]);

    return (
        <div className={classes.actions}>
          <Tooltip title="Edit">
            <div>
                <EditButton label="" basePath='/subscription' record={record}/>
            </div>
          </Tooltip>
          <Tooltip title="Delete">
            <div>
                <Button
                    onClick={onDelete}
                >
                    <DeleteIcon
                        color='error'
                        fontSize='small'
                    />
                </Button>
            </div>
          </Tooltip>
        </div>
      );
};

export const SubscriptionList = props => {

    const [open, setOpen] = useState(false);

    const onTriggerDialog = useCallback((state) => {
        setOpen(state);
    }, [setOpen]);

    const columns = {
        name: {
            width: '30%',
        },
        price: {
            width: '30%',
        },
        is_status: {
            width: '30%',
        },
        actions: {
            width: '10%',
            align: 'center'
        }
    }

    return (
        <>
            <List
                {...props}
                title='Subscriptions'
                bulkActionButtons={false}
                sort={{
                    field: 'id',
                    order: 'desc'
                }}
                exporter={false}
                pagination={<SubscriptionPagination/>}
                actions={<SubscriptionListActions/>}
                filters={subscriptionFilter}
            >
                <Datagrid header={<CustomHeader columns={columns}/>}>
                    <TextField
                        source='name'
                        sortable
                    />
                    <FunctionField 
                        source='price'
                        label='Price'
                        render={(record) => `${record?.price ? `${record.price} NGN` : '-'}`}
                        sortBy='price'
                        sortable
                    />
                    <FunctionField
                        sortable
                        source='is_active'
                        label='Status'
                        render={(record) => `${record?.is_active ? 'Active' : 'Inactive'}`}
                    />
                    <ActionField
                        source='actions'
                        sortable={false}
                        onTriggerDialog={onTriggerDialog}
                    />
                    
                </Datagrid>
            </List>
            <Dialog
                open={open}
                onClose={() => onTriggerDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Subscription warning
                </DialogTitle>
                <DialogContent>
                    You can not delete a subscription that has active users
                </DialogContent>
                <DialogActions>
                    <MButton
                        onClick={() => onTriggerDialog(false)}
                    >Close</MButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export const SubscriptionEdit = (props) => {
    return (
        <Edit {...props}>
            <SubscriptionForm {...props}/>
        </Edit>
    );
}

export const SubscriptionCreate = (props) => {
    return (
        <Create {...props}>
            <SubscriptionForm {...props}/>
        </Create>
    );
}