import { Edit, SimpleForm, TextInput, required,
         Create, maxLength } from 'react-admin';

import { NonInput } from '../../../utils';

import { makeStyles  } from '@material-ui/core/styles';

import CancelToolbar from '../../layout/CancelToolbar';


const useStyles = makeStyles(theme => ({
  name: {
    width: '50%',
  },
  title: {
    fontSize: '1.4em',
    fontWeight: 'bold',
  },
}));

const CategoryEditTitle = props => (
  <span>
    {props.record ? props.record.name : 'Change content category'}
  </span>
);

const validateName = [
  required('Please, enter the Name of the category'),
  maxLength(100, 'Name of the category should be less than 100 symbols')];

export const CategoryEdit = props => {
  const classes = useStyles();

  const toolbar = <CancelToolbar 
  goto="/content_categories"
  question="You have some unsaved data. Are you sure you want to proceed?"
  confirm="save"/>;

  return (
    <Edit {...props} title={<CategoryEditTitle />}>
      <SimpleForm toolbar={toolbar}>
        <NonInput>
          <p className={classes.title}>Edit Category</p> 
        </NonInput>
        <TextInput 
          source="name" 
          label="Name of the category" 
          className={classes.name} 
          validate={validateName} />
      </SimpleForm>
    </Edit>
  );
};

export const CategoryCreate = props => {
  const classes = useStyles();
  
  const toolbar = <CancelToolbar 
    goto="/content_categories"
    question="You have some unsaved data. Are you sure you want to proceed?"
    confirm="create"/>;

  return (
    <Create {...props} title="Add New Category">
      <SimpleForm 
        toolbar={toolbar}
        redirect="list">
          <NonInput>
            <p className={classes.title}>Add new Category</p> 
          </NonInput>
          <TextInput 
            source="name" 
            label="Name of the category" 
            className={classes.name} 
            validate={validateName} />
      </SimpleForm>
    </Create>
  )
}