import { Admin, Resource, Layout, AppBar, UserMenu, MenuItemLink, ListGuesser } from 'react-admin';
import { Route } from 'react-router-dom';
import { forwardRef } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';


import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';

import Login from './components/auth/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import ForgotPassword from './components/auth/forgotPassword/ForgotPassword';
import ResetPassword from './components/auth/resetPassword/ResetPassword';
import ChangePassword from './components/auth/changePassword/ChangePassword';
import BookList from './components/data/books/BookList';
import BookEdit from './components/data/books/BookEdit';
import BookCreate from './components/data/books/BookCreate';
import ArchiveList from './components/data/archive/ArchiveList';
import CategoryList from './components/data/contentCategories/ContentCategoryList';
import { CategoryCreate, CategoryEdit } from './components/data/contentCategories/ContentCategoryAddEdit';
import { ProfileEdit, useProfile, ProfileProvider } from './components/profile/Profile';
import { UsersList, UsersEdit } from './components/data/users';
import { SubscriptionList, SubscriptionEdit, SubscriptionCreate } from './components/data/subscriptions';
import { PaymentsList } from './components/data/payments';
import { PromoCodeList, PromoCodeEdit, PromoCodeCreate, PromoCodeShow } from './components/data/promocodes';
import { ClassesList, ClassCreate, ClassEdit } from './components/data/classes';
import { SubjectList, SubjectCreate, SubjectEdit } from './../src/components/data/subjects'

function App() {
  return (
    <Admin
      dashboard={Dashboard} 
      authProvider={authProvider} 
      dataProvider={dataProvider}
      loginPage={Login}
      layout={MyLayout}
      customRoutes={[
        <Route exact path='/forgot-password' component={ForgotPassword} noLayout/>,
        <Route exact path='/reset-password' component={ResetPassword} noLayout />,
        <Route exact path='/change-password' component={ChangePassword} />,
        <Route exact path='/my-profile' render={() => <ProfileEdit />} />,
      ]}>
        <Resource 
          name="books" 
          list={BookList} 
          edit={BookEdit}
          create={BookCreate} />
        <Resource name="archive" list={ArchiveList} options={{ label: 'Archive' }} />
        <Resource name="content_categories" 
          list={CategoryList}
          edit={CategoryEdit}
          create={CategoryCreate}
          options={{ label: 'Content Categories' }} />
        <Resource
          name="users" 
          list={UsersList}
          edit={UsersEdit} 
        />
        <Resource
          name="subscription"
          list={SubscriptionList}
          create={SubscriptionCreate}
          edit={SubscriptionEdit}
        />
        <Resource
          name='payment'
          list={PaymentsList}
        />
        <Resource
          name='promocode'
          options={{
            label: 'Vouchers'
          }}
          list={PromoCodeList}
          create={PromoCodeCreate}
          edit={PromoCodeEdit}
          show={PromoCodeShow}
        />
        <Resource
          name='testclasses'
          options={{
            label: 'Manage classes'
          }}
          list={ClassesList}
          edit={ClassEdit}
          create={ClassCreate}
        />
        <Resource
          name='testsubjects'
          options={{
            label: 'Manage subjects'
          }}
          list={SubjectList}
          edit={SubjectEdit}
          create={SubjectCreate}
        />
    </Admin>
  );
}

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
  <>
    <MenuItemLink
        ref={ref}
        to="/my-profile"
        primaryText="My Profile"
        leftIcon={<PersonIcon />}
        onClick={onClick}
    />
    <MenuItemLink
        ref={ref}
        to="/change-password"
        primaryText="Change Password"
        leftIcon={<SettingsIcon />}
        onClick={onClick}
    />
  </>
));

const MyUserMenu = props => {
  const { profileVersion } = useProfile();
  return (
  <UserMenu key={profileVersion} {...props}>
      <ConfigurationMenu />
  </UserMenu>
  );
};

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;

const MyLayout = props => <ProfileProvider>
    <Layout {...props} appBar={MyAppBar} />;
  </ProfileProvider>

export default App;
