import { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Toolbar, Button } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DialogContextText from '@material-ui/core/DialogContentText';
import { makeStyles  } from '@material-ui/core/styles';

const useToolbarStyles = makeStyles(theme => ({
  bar: {
    display: 'flex',
    justifyContent: 'center',
  },
  cancel: {
    marginRight: '20px',
  }
}));

export const UserFormToolbar = ({
    touched,
    goto,
    question,
    confirm,
    cancel,
    isActive,
    onRestrict,
    ...props
}) => {

    const [open, setOpen] = useState(false);
    const classes = useToolbarStyles();
    const history = useHistory();

    const handleClose = useCallback(() => {
        setOpen(false);
    });

    const handleOpen = useCallback(() => {
        setOpen(true);
    });

    const handleModal = useCallback((value) => {
        
        onRestrict(value);

        setOpen(false);

    }, [onRestrict]);

    const onGoBack = useCallback(() => {
        history.push(goto ?? '/dashboard');
    });

    return (
        <Toolbar {...props} className={classes.bar}>
            <Button 
                label={cancel || 'Cancel'} 
                variant="contained" 
                size="medium"
                className={classes.cancel}
                onClick={onGoBack}
            />
            
            <Button
                variant='contained'
                size='medium'
                label={isActive ? 'Restrict' : 'Unrestrict'}
                onClick={handleOpen}
            />

            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    <DialogContextText>
                    {`Are you sure you want to ${isActive ? 'Restrict' : 'Unrestrict'} this user?`}
                    </DialogContextText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleModal(false)} color="primary" label="Cancel" />
                    <Button onClick={() => handleModal(true)} color="primary" autoFocus label="Confirm" />
                </DialogActions>
            </Dialog>
        </Toolbar>
    );
}
