import {
    List, 
    Datagrid,
    Pagination,
    TextField,
    TextInput,
    FunctionField,
} from 'react-admin';
import CustomHeader from '../../layout/CustomHeader';
import { customExport } from './../../../providers/dataProvider';

const PaymentsPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const PaymentsFilter = [
    <TextInput source='name' label='Name' alwaysOn/>
];

const exporter = () => {

    customExport('payment/get_csv');
}

export const PaymentsList = props => {

    const columns = {
        date: {
            width: '25%',
        },
        subscription_name: {
            width: '25%',
        },
        user: {
            width: '25%',
        },
        amount: {
            width: '25%'
        }
    }

    return (
        <List
            {...props}
            title='Payments'
            bulkActionButtons={false}
            exporter={exporter}
            sort={{
                field: 'date',
                order: 'desc'
            }}
            pagination={<PaymentsPagination/>}
            filters={PaymentsFilter}
        >
            <Datagrid header={<CustomHeader columns={columns}/>}>
                <FunctionField
                    source='date'
                    label='Date'
                    render={
                        (record) => record?.date 
                            ? new Date(record.date).toLocaleString({ 
                                hour: 'numeric',
                                hour12: true,
                            }).replace(',', '') 
                            : '-'
                    }
                />
                <TextField
                    label='Subscription'
                    source='subscription_name'
                />
                <FunctionField
                    source='user'
                    label='User'
                    render={(record) => record?.first_name ? `${record.first_name} ${record.last_name}` : '-'}
                />
                <FunctionField
                    source='amount'
                    label='Amount'
                    render={
                        (record) => `${record.amount} NGN`
                    }
                />
            </Datagrid>
        </List>
    );
}