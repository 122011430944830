import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import {
  TextInput,
  ImageInput,
  ImageField,
  SimpleForm,
  required,
  useDataProvider,
  useNotify,
  SaveContextProvider,
  useGetIdentity,
} from "react-admin";

import { makeStyles } from "@material-ui/core";

import CancelToolbar from "../layout/CancelToolbar";
import { NonInput } from "../../utils";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.4em',
    fontWeight: 'bold',
  },
  field: {
    width: '40%',
  },
}));

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1)
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

export const ProfileEdit = ({ staticContext, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState();
  const { refreshProfile } = useProfile();
  const classes = useStyles();
  const [touched, setTouched] = useState(false);

  const { loaded, identity } = useGetIdentity();

  const handleSave = useCallback(
    (values) => {
      setSaving(true);
      dataProvider.updateUserProfile(
        { data: values },
        {
          onSuccess: ({ data }) => {
            setSaving(false);
            notify("Your profile has been updated", "info", {
              _: "Your profile has been updated"
            });
            refreshProfile();
          },
          onFailure: () => {
            setSaving(false);
            notify(
              "A technical error occured while updating your profile. Please try later.",
              "warning",
              {
                _:
                  "A technical error occured while updating your profile. Please try later."
              }
            );
          }
        }
      );
    },
    [dataProvider, notify, refreshProfile]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving
    }),
    [saving, handleSave]
  );

  if (!loaded) {
    return null;
  }

  const formatLogo = value => {
    if (!value || typeof value === 'string') {
      return { url: value };
    }
    return value;
  }

  const toolbar = <CancelToolbar 
    goto="/" 
    confirm="Save"
    question="Are you sure you want to leave the page? You have unsaved changes."
    touched={touched}
    />

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm save={handleSave} toolbar={toolbar} record={identity ? identity : {}}>
        <NonInput>
            <p className={classes.title}>My Profile</p>
        </NonInput>
        <ImageInput 
          source="avatar"
          label="Profile picture"
          validate={required('Please make sure you added a picture')}
          format={formatLogo}
          className={classes.field}>
          <ImageField source="url"/>
        </ImageInput>
        <TextInput 
          source="fullName" 
          label="Username" 
          validate={required('Please make sure you have entered your name')}
          className={classes.field}
          onInput={() => setTouched(true)} />
        <TextInput 
          source="email" 
          label="Email"
          disabled 
          className={classes.field} />
      </SimpleForm>
    </SaveContextProvider>
  );
};