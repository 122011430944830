import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Toolbar, Button } from 'react-admin';
import { makeStyles  } from '@material-ui/core/styles';

const useToolbarStyles = makeStyles(theme => ({
  bar: {
    display: 'flex',
    justifyContent: 'center',
  },
  cancel: {
    marginRight: '20px',
  }
}));

export const PromoCodeToolbar = ({
    onSubmit,
    ...props
}) => {


    const classes = useToolbarStyles();
    const history = useHistory();

    const onGoBack = useCallback(() => {

        history.push('/promocode');

    }, [history]);

    const handleSave = useCallback(() => {
        onSubmit();
    }, [onSubmit]);

    return (
        <Toolbar {...props} className={classes.bar}>
            <Button 
                label='Cancel' 
                variant='contained' 
                size='medium'
                className={classes.cancel}
                onClick={onGoBack}
            />
            <Button
                variant='contained'
                size='medium'
                label='Save'
                onClick={handleSave}
            />
        </Toolbar>
    );
}
