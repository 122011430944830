import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';

const Dashboard = () => (
  <Card>
    <CardHeader title="Welcome to administration" />
    <CardContent>uLearn administration</CardContent>
  </Card>
);

export default Dashboard;