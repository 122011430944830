import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
    useDataProvider,
    useNotify,
    Button,
} from 'react-admin';
import {
    Grid,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import * as yup from 'yup';
import { useFormik } from 'formik';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: '#f4f4f4',
        textAlign: 'center',
        padding: 16
    },
    headerContainer: {
        margin: '32px 16px 0'
    },
    cancel: {
        marginRight: 20
    },
    container: {
        padding: 16
    }
}));


export const ClassForm = (props) => {

    const { record } = props;

    const classes = useStyles();

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const history = useHistory();

    const [initialValues, setInitialValues] = useState({
        name: '',
        is_active: false,
        is_default: false,
    });

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required(),
    });

    useEffect(() => {
        if (record.id) {
            setInitialValues({
                name: record.name,
                is_active: record.is_active,
                is_default: record.is_default,
            });
        }
    }, [record]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (data) => {

            if (record?.id) {
        
                dataProvider.update('testclasses', {
                    id: record.id,
                    data,
                    previousData: record
                }, {
                    onSuccess: () => {
                        notify('Class was updated successful', 'success');
                        history.push('/testclasses');
                    },
                    onFailure: (error) => {
                        notify('Something went wrong');
                    }
                })
    
            } else {
                
                dataProvider.create('testclasses', {
                    data
                }, {
                    onSuccess: () => {
                        notify('Class was created successful', 'success');
                        history.push('/testclasses');
                    },
                    onFailure: (error) => {
                        notify('Something went wrong');
                    }
                });
            }
        }
    });

    const onCancel = () => {
        history.push('/testclasses');
    }

    return (
        <div>
            <h2 className={classes.headerContainer}>{record?.id ? 'Edit' : 'Add new'} class</h2>
                <form
                    onSubmit={formik.handleSubmit}
                >
                    <div className={classes.container}>
                        <Grid container>
                            <Grid item>
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        margin='normal'
                                        name='name'
                                        label='Name'
                                        variant='filled'
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox/>}
                                        label='Active'
                                        name='is_active'
                                        onChange={formik.handleChange}
                                        checked={formik.values.is_active}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox/>}
                                        label='Default'
                                        name='is_default'
                                        onChange={formik.handleChange}
                                        checked={formik.values.is_default}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.toolbar}>
                        <Button
                            label='Cancel'
                            variant='contained'
                            size='medium'
                            onClick={onCancel}
                            className={classes.cancel}
                        />
                        <Button
                            label='Save'
                            type='submit'
                            variant='contained'
                            size='medium'
                        />
                    </div>
                </form>
        </div>
    );
}